import React, { Fragment, Component } from 'react';
import Header from './../../Layout/Header';
import Footer2 from './../../Layout/Footer2';
import PageTitle from './../../Layout/PageTitle';
import Mapview from './../../Element/Mapview';
import EventSection from '../Homepage/EventSection';
import SwiperSlider from './SwiperSlider';
import WOW from 'wowjs';
import breadcrumbImage from '../../../images/banner.png';
//Images 
import bnr1 from './../../../images/amenities/bg/pic1.jpg';
import bnr2 from './../../../images/amenities/bg/pic2.jpg';
import bnr3 from './../../../images/amenities/bg/pic3.jpg';
import { companyInfo } from '../../../Api/Actions/companyInfo';
import { TeamSection } from './Aboutus2';
import { managementInfo } from '../../../Api/Actions/Management';
import { Modal } from 'react-bootstrap';
import { cardButton } from '../../CommonStyle/CommonStyle';
import { Helmet } from 'react-helmet';

const backgrounBlog = [
  { image: bnr1, title: 'Dive in To The Aquamarine waters of our swanky Swimming pool', },
  { image: bnr2, title: 'Temple', },
  { image: bnr3, title: 'Squash Court', },
];

class Aboutus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  componentDidMount() {
    new WOW.WOW().init();
    this.fetchCompanyInfo();
    this.fetchManagement();
  }

  fetchManagement = () => {
    managementInfo().then(response => {
      this.setState({
        managementInfo: response.data
      })
    })
  }
  fetchCompanyInfo = () => {
    companyInfo().then(response => {
      this.setState({
        companyInfo: response.data,
      })
    })
  }
  openModal = (bioInfo) => {
    this.setState({ isOpen: true, bioInfo: bioInfo })
    // console.log('bookInfo', bookInfo)
  };
  closeModal = () => { this.setState({ isOpen: false, bookIn: '' }) };
  render() {
    const imageLink = process.env.REACT_APP_IMAGE_URL;
    let companyInfo = [];
    let management = [];
    if (this.state.hasOwnProperty('companyInfo')) {
      companyInfo = this.state.companyInfo[0];
    }

    if (this.state.hasOwnProperty('managementInfo')) {
      management = this.state.managementInfo;
    }
    let aboutUs = <div dangerouslySetInnerHTML={{ __html: companyInfo.about_us }} />
    var strippedHtml = companyInfo?.about_us?.replace(/<[^>]+>/g, '').slice(0, 300);
    return (
      <Fragment>
        <Helmet>
          <title>About Us -Aspen Square Homes</title>
          <meta name="description" content={strippedHtml} />
          <meta property="og:title" content="About Us -Aspen Square Homes" />
          <meta property="og:type" content="aspensquarehomes" />
          <meta property="og:description" content={strippedHtml} />
          <meta property="og:url" content="https://aspensquarehomes.com/about-us" />
          <meta property="og:image" content={`${imageLink + 'company_settings/about_us_cover_image_1/' + companyInfo.about_us_cover_image_1}`} />
        </Helmet>
        <Header />
        <div className="page-content bg-white">
          <PageTitle motherMenu="About us " activeMenu="About Us " breadcrumbImage={imageLink + 'company_settings/about_us_cover_image_1/' + companyInfo.about_us_cover_image_1} />
          {/* Section-1 End */}
          <section className="content-inner-1" data-content="ABOUT US">
            <div className="container">
              <div className="section-head text-center">
                <h2 className="title wow fadeInDown" data-wow-duration='2s' data-wow-delay="0.4s">{companyInfo.f_about_us_info}</h2>
                <p className="text-justify wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s" style={{ fontSize: '14px' }}>{aboutUs ?? ''}</p>
              </div>
              {/* <div className="row">
								<div className="col-md-4 col-lg-2 col-6 mb-4 wow bounceInUp" data-wow-duration='2s' data-wow-delay="0.2s">
									<div className="aminite-icon-bx"><i className="flaticon-pool"></i><h4 className="title">Swimming Pool</h4></div>
								</div>
								<div className="col-md-4 col-lg-2 col-6 mb-4 mt-lg-3 mt-0 wow bounceInUp" data-wow-duration='2s' data-wow-delay="0.3s">
									<div className="aminite-icon-bx"><i className="flaticon-seats-at-the-hall"></i><h4 className="title">Club House</h4></div>
								</div>
								<div className="col-md-4 col-lg-2 col-6 mb-4 mt-lg-5 mt-0 wow bounceInUp" data-wow-duration='2s' data-wow-delay="0.4s">
									<div className="aminite-icon-bx"><i className="flaticon-jogging-1"></i><h4 className="title">Walking Trail</h4></div>
								</div>
								<div className="col-md-4 col-lg-2 col-6 mb-4 mt-lg-5 mt-0 wow bounceInUp" data-wow-duration='2s' data-wow-delay="0.4s">
									<div className="aminite-icon-bx"><i className="flaticon-playground"></i><h4 className="title">Kids Play </h4></div>
								</div>
								<div className="col-md-4 col-lg-2 col-6 mb-4 mt-lg-3 mt-0 wow bounceInUp" data-wow-duration='2s' data-wow-delay="0.3s">
									<div className="aminite-icon-bx"><i className="flaticon-meeting"></i><h4 className="title">Picnic & Barbecue </h4></div>
								</div>
								<div className="col-md-4 col-lg-2 col-6 mb-4 wow bounceInUp" data-wow-duration='2s' data-wow-delay="0.2s">
									<div className="aminite-icon-bx"><i className="flaticon-book"></i><h4 className="title">Schools</h4></div>
								</div>
							</div> */}
            </div>
          </section>

          {
            management && management.length == 0 ? '' :
              <section className="content-inner-2" data-content="MANAGEMENT">
                <div className="container">
                  <div className="section-head text-center">
                    <h2 className="title">Our Management</h2>

                  </div>
                  <div className="row my-3 justify-content-center">
                    {management && management.map((data, index) => (
                      <div className="col-lg-3 col-md-6 col-sm-6 m-md-b30" key={index}>
                        <div className="our-team " onClick={() => this.openModal(data)} style={{ cursor: 'pointer' }}>
                          <div className="dlab-media radius-sm" style={{ background: '#000' }}>
                            <img src={imageLink + 'company_settings/aboutus/slider_large_image/' + data.slider_large_image} className="w-100" style={{ height: '250px', objectFit: 'cover', border: '1px solid #000' }} alt="" />
                            <h2 className="team-title text-center text-primary" onClick={() => this.openModal(data)} style={{ cursor: 'pointer' }}>{data.slider_header_title}</h2>
                            <p className=" text-center">{data.slider_title}</p>
                            {/* <p className=" text-justify " style={{ fontSize: '10px' }}><div dangerouslySetInnerHTML={{ __html: data.short_description }} /></p> */}
                          </div>
                          {/* <div className="team-title-bx text-center">
										<h2 className="team-title">{data.short_description.substring(0,150)}...</h2>
										<span className="clearfix">{data.short_description}</span>
									</div> */}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <Modal show={this.state.isOpen} size="xl" onHide={this.closeModal} animation={true} centered scrollable>
                  <Modal.Body>
                    <div className="row justify-content-center">
                      <div className="col-md-12">
                        <div className="row justify-content-center">
                          <div className="col-md-12 col-lg-12 col-sm-12">
                            <div className="d-flex justify-content-center">
                              {this.state.bioInfo && <img src={imageLink + 'company_settings/aboutus/slider_large_image/' + this.state.bioInfo.slider_large_image} alt="bio" style={{ height: '250px', widtg: '250px' }} />}
                            </div>
                            <div>
                              {this.state.bioInfo && <h2 className="team-title text-center text-primary">{this.state.bioInfo.slider_header_title}</h2>}
                              {this.state.bioInfo && <h2 className="team-title text-center text-info">{this.state.bioInfo.slider_title}</h2>}
                            </div>
                          </div>

                        </div>
                        <div className="mt-3">
                          <h2 className="double-rules" >Bio</h2>
                          {this.state.bioInfo && <p className=" text-justify " style={{ fontSize: '14px' }}><div dangerouslySetInnerHTML={{ __html: this.state.bioInfo.short_description }} /></p>}
                          {this.state.bioInfo && <p className=" text-justify " style={{ fontSize: '14px' }}><div dangerouslySetInnerHTML={{ __html: this.state.bioInfo.long_descriptiom }} /></p>}
                        </div>

                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="p-2">
                    <button className="btn btn-primary btn-sm" onClick={this.closeModal}>
                      Close
                    </button>
                  </Modal.Footer>

                </Modal>

              </section>
          }


          {/* <TeamSection management={management && management} /> */}


          {/* <Mapview />	 */}

          {/* <section className="content-inner-1" data-content="MASTER PLAN" id="masterPlan">
						<div className="container">
							<div className="section-head text-center">
								<h2 className="title wow fadeInDown" data-wow-duration='2s' data-wow-delay="0.4s">Site Plan & Master Plan</h2>
								<div className="dlab-separator bg-primary"></div>
								<p className="wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">With premium finishes and wide-open spaces, each floor plan brings you unparalleled luxury without sacrificing on comfort. Floor plans are artist’s rendering. All dimensions are approximate.</p>
							</div>
						</div>
						<SwiperSlider />


					</section> */}
          <div>
            <img src={imageLink + 'company_settings/about_us_cover_image_2/' + companyInfo.about_us_cover_image_2} className="bg-view" alt="" />
          </div>
        </div>

        <Footer2 />
      </Fragment>
    )
  }
}

export default Aboutus;
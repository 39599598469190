import selectedImg from '../../../assets/BookingInfo/29Alot.png';

const BookingInfo = [
    {   
        f_id:4,
        building_no:5,
        block:'G',
        Lot:6,
        Model:'Breckenridge',
        sqft:2489,
        phase:'First',
        img:selectedImg,

    },
    {   
        f_id:4,
        building_no:6,
        block:'G',
        Lot:14,
        Model:'Breckenridge',
        sqft:2489,
        phase:'First',
        img:selectedImg,

    },
    {   
        f_id:5,
        building_no:11,
        block:'A',
        Lot:29,
        Model:'Durango',
        sqft:2651,
        phase:'Second',
        img:selectedImg,

    },
    {   
        f_id:5,
        building_no:13,
        block:'A',
        Lot:44,
        Model:'Durango',
        sqft:2651,
        phase:'Second',
        img:selectedImg,

    },
    {   
        f_id:6,
        building_no:9,
        block:'A',
        Lot:15,
        Model:'Taos',
        sqft:2517,
        phase:'Second',
        img:selectedImg,

    },
    {   
        f_id:6,
        building_no:11,
        block:'A',
        Lot:26,
        Model:'Taos',
        sqft:2517,
        phase:'Second',
        img:selectedImg,

    },
    {   
        f_id:6,
        building_no:11,
        block:'A',
        Lot:28,
        Model:'Taos',
        sqft:2517,
        phase:'Second',
        img:selectedImg,

    },
    {   
        f_id:6,
        building_no:12,
        block:'A',
        Lot:39,
        Model:'Taos (Edge)',
        sqft:2517,
        phase:'Second',
        img:selectedImg,
    },
    {   
        f_id:8,
        building_no:21,
        block:'C',
        Lot:13,
        Model:'Vail',
        sqft:2517,
        phase:'Second',
        img:selectedImg,
    },
]

export default BookingInfo;
import React, { Component } from 'react';
import { FacebookProvider, Page } from 'react-facebook';

export default class FacebookPage extends Component {
    render() {
        return (
            //   <FacebookProvider appId="917905122451656">
            //     <Page href="https://www.facebook.com/ErevnitesGaming" tabs="timeline" />
            //   </FacebookProvider>    
            <iframe href="https://www.facebook.com/gaming/ErevnitesGaming/"></iframe>
        );
    }
}
import React from "react";
import {Link} from 'react-router-dom';
import { breadcrumbStyle } from "../CommonStyle/CommonStyle";

const ErrorPage = ({breadcrumbImage }) => {
   return (
	
		<div className="dlab-bnr-inr" style={breadcrumbStyle(breadcrumbImage)}>
			<div className="container">
				<div className="dlab-bnr-inr-entry">
					{/* <h1>{motherMenu}</h1> */}
					<nav aria-label="breadcrumb" className="breadcrumb-row">
						<ul className="breadcrumb">
							<li className="breadcrumb-item"><Link to={"#"}><i className="las fa-home mr-2"></i>Home</Link></li>
							<li className="breadcrumb-item active" aria-current="page">{'Error'}</li>
						</ul>
					</nav>
					
				</div>
			</div>
		</div>
		
   );
};

export default ErrorPage;


			
import React, { Component } from "react";
import Slider from "react-slick";

import pic1 from './../../../images/gallery/carousel/pic1.jpg';
import thumb1 from './../../../images/gallery/carousel/thumb1.jpg';

const galleryBlog = [
  { image: pic1, },
  { image: pic1, },
  { image: pic1, },
  { image: pic1, },
  { image: pic1, },
  { image: pic1, },
]


export default class SwiperSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    var settings = {
      arrows: false,
      centerMode: true,

    };
    return (
      <div>

        <div className="row spno">
          <div className="col-md-6 col-sm-12 mb-2">
            {/* <img src={map} alt="" /> */}
            <img src="https://aspensquarehomes.com/wp-content/uploads/2022/06/Merritt_Villas_Site_Plan.jpg" alt="" />
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d227748.3825624477!2d75.65046970649679!3d26.88544791796718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c4adf4c57e281%3A0xce1c63a0cf22e09!2sJaipur%2C+Rajasthan!5e0!3m2!1sen!2sin!4v1500819483219"
								className="border-0 align-self-stretch rounded-sm" height="100%"
								width="100%" allowfullscreen
							/> */}
          </div>
          <div className="col-md-6 col-sm-12 mb-2">
            <iframe
              src="https://aspensquarehomes.com/wp-content/uploads/2021/08/Site_Plan.pdf"
              frameBorder="0"
              scrolling="none"
              height="100%"
              width="100%">
            </iframe>
            {/* <img src="https://aspensquarehomes.com/wp-content/uploads/2022/06/Merritt_Villas_Site_Plan.jpg" className="w-75 img-fluid img-responsive" alt="" /> */}
            {/* <img src={sitemap} alt="" /> */}

          </div>
        </div>

        {/* <Slider
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
		  className="owl-carousel owl-theme owl-none owl-dots-none gallery-top "
		  {...settings}
        >
			
			{galleryBlog.map((item, index) => (
				<div className="item" key={index}>
					<div className="gallery-media">
						<img src={item.image} alt=""  style={{width:"100%"}}  />
						<h4 className="title">40 X 70 East Side FF</h4>
					</div>
				</div>
			))}	
			
          
        </Slider> */}

        {/* <Slider
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
          slidesToShow={5}
          swipeToSlide={true}
          focusOnSelect={true}
		  className="owl-carousel owl-theme owl-none owl-dots-none gallery-bottom container owl-btn-center-lr "
		  {...settings}
        >
          <div>
            
			<div className="item p-2" >
				<div className="gallery-box">
					<img src={thumb1} alt="" style={{width:"100%"}} / >
				</div>
			</div>
				
          </div>
          <div>
            <div className="item p-2" >
				<div className="gallery-box">
					<img src={thumb1} alt="" style={{width:"100%"}} / >
				</div>
			</div>
          </div>
          <div>
            <div className="item p-2" >
				<div className="gallery-box">
					<img src={thumb1} alt="" style={{width:"100%"}} / >
				</div>
			</div>
          </div>
          <div>
           <div className="item p-2" >
				<div className="gallery-box">
					<img src={thumb1} alt="" style={{width:"100%"}} / >
				</div>
			</div>
          </div>
          <div>
            <div className="item p-2" >
				<div className="gallery-box">
					<img src={thumb1} alt="" style={{width:"100%"}} / >
				</div>
			</div>
          </div>
          <div>
             <div className="item p-2" >
				<div className="gallery-box">
					<img src={thumb1} alt="" style={{width:"100%"}} / >
				</div>
			</div>
          </div>
        </Slider> */}
      </div>
    );
  }
}


import React, { Fragment, Component } from 'react';
import Header from './../../Layout/Header';
import Footer2 from './../../Layout/Footer2';
import PageTitle from './../../Layout/PageTitle';
// import Mapview from './../../Element/Mapview';
// import EventSection from '../Homepage/EventSection';
// import SwiperSlider from './SwiperSlider';
import WOW from 'wowjs';
import breadcrumbImage from '../../../images/banner.png';

//Images 
import bnr1 from './../../../images/amenities/bg/pic1.jpg';
import bnr2 from './../../../images/amenities/bg/pic2.jpg';
import bnr3 from './../../../images/amenities/bg/pic3.jpg';
import { companyInfo } from '../../../Api/Actions/companyInfo';
// import { TeamSection } from './Aboutus2';
import { managementInfo } from '../../../Api/Actions/Management';
import { Modal } from 'react-bootstrap';
import { cardButton } from '../../CommonStyle/CommonStyle';
import pro_thumb from '../../../assets/common/profile_thumb.jpg';
import { investors } from '../../../Api/Actions/investors';
import Fade from 'react-reveal/Fade';
import { Helmet } from 'react-helmet';

const backgrounBlog = [
  { image: bnr1, title: 'Dive in To The Aquamarine waters of our swanky Swimming pool', },
  { image: bnr2, title: 'Temple', },
  { image: bnr3, title: 'Squash Court', },
];

class OurInvestor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  componentDidMount() {
    new WOW.WOW().init();
    this.fetchCompanyInfo();
    this.fetchInvestors();
  }

  fetchInvestors = () => {
    investors().then(response => {
      this.setState({
        investors: response.data
      })
    })
  }
  fetchCompanyInfo = () => {
    companyInfo().then(response => {
      this.setState({
        companyInfo: response.data,
      })
    })
  }
  openModal = (bioInfo) => {
    this.setState({ isOpen: true, bioInfo: bioInfo })
    // console.log('bookInfo', bookInfo)
  };
  closeModal = () => { this.setState({ isOpen: false, bookIn: '' }) };
  render() {
    const imageLink = process.env.REACT_APP_IMAGE_URL;
    let companyInfo = [];
    let investors = [];
    if (this.state.hasOwnProperty('companyInfo')) {
      companyInfo = this.state.companyInfo[0];
    }

    if (this.state.hasOwnProperty('investors')) {
      investors = this.state.investors;
    }
    // console.log('investors', investors)
    // let aboutUs = <div dangerouslySetInnerHTML={{ __html: companyInfo.about_us }} />


    return (
      <Fragment>
        <Helmet>
          <title>Our Investors -Aspen Square Homes</title>
          <meta name="description" content="Our mission is to enrich our customers' lives with an exceptional customer experience by connecting our customers with skillful and professional team members, provide innovative craftsmanship, designs, and products, and offer utmost personal care — one customer, one home, and one community at a time." />
          <meta property="og:title" content="Our Investors -Aspen Square Homes" />
          <meta property="og:type" content="aspensquarehomes" />
          <meta property="og:description" content="Our mission is to enrich our customers' lives with an exceptional customer experience by connecting our customers with skillful and professional team members, provide innovative craftsmanship, designs, and products, and offer utmost personal care — one customer, one home, and one community at a time." />
          <meta property="og:url" content="https://aspensquarehomes.com/our-investors" />
          <meta property="og:image" content={`${imageLink + 'company_settings/about_us_cover_image_1/' + companyInfo.about_us_cover_image_1}`} />
        </Helmet>
        <Header />
        <div className="page-content bg-white">
          <PageTitle motherMenu="INVESTORS" activeMenu="Investors" breadcrumbImage={imageLink + 'company_settings/about_us_cover_image_1/' + companyInfo.about_us_cover_image_1} />

          <section className="content-inner-2" data-content="INVESTORS">
            <div className="container">
              <div className="section-head text-center">
                <h2 className="double-rules">INVESTORS</h2>
                {/* <div className="entry-content text-center my-2">
                                    <p>
                                        Our senior leaders have more than 200 years of combined homebuilding experience, expertise that is passed along to each individual customer. Our team of professionals is dedicated to you from the moment you visit our model to well after you’ve moved in.
                                    </p>
                                </div> */}
              </div>

              <div className="row d-flex align-items-stretch justify-content-center pt-3">

                <p>Our mission is to reach new heights and become the standard bearer of the real estate industry by creating the best living spaces. Our strength lies in our experience, skill and dedication to deliver the best. Keep showering your love and trust on us and we shall pursue our dreams, while we carry out yours!</p>
                <p>With unwavering vision to offer a sustainable balance between affordability and luxury, we bring you homes to enthrall your heart. We build homes with a global standard of construction with a great sense of environmental responsibility.</p>
                <p>Our target investors are working individuals who are looking for safe asset base investment. These investors have money invested in CD’s, banks and are getting an average return of 2% interest per year on their investment. The idea is to invest in hard assets in base projects (rental townhomes) and get a better rate of return. Once the townhome closing is done, refinance the home and get the part of initial investment out and have the rental townhome paying for itself (Mortgage, HOA and Insurance).</p>
                <hr></hr>
                {/* {investors && investors.map((investor, index) =>
                                    <Fade bottom>
                                        <div className="col-md-4 col-xl-2 mb-4 mb-xl-5" key={index}>
                                            <div className="team-wrap bg-dark text-white h-100 mx-xl-2">
                                                {
                                                    investor.investor_image == null || investor.investor_image == null || investor.investor_image == "" ?
                                                        <div className="w-100 rectangle tall bg-image cover mb-3 mb-md-0" style={{ backgroundImage: "url(" + pro_thumb + ")", border: '1px solid #569539' }}></div>
                                                        :
                                                        <div className="w-100 rectangle tall bg-image cover mb-3 mb-md-0" style={{ backgroundImage: "url(" + imageLink + 'Investor/' + investor.Buyer_First_Name + '/' + investor.investor_image + ")", border: '1px solid #569539' }}></div>
                                                }
                                                
                                                <div className="d-flex p-3">
                                                    <div className="flex-grow-1">
                                                        <h4>{investor.Buyer_First_Name} {investor.Buyer_Middle_Name} {investor.Buyer_Last_Name}</h4>
                                                        <hr /><h5>Investors</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Fade>
                                )} */}
              </div>

            </div>
          </section>
        </div>

        <Footer2 />
      </Fragment>
    )
  }
}

export default OurInvestor;
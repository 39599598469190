import { getApi } from "../../services/httpService";


export function floorPlanDetails(slug) {
  return getApi().get('home/floorPlan/details/' + slug);
}

export function moveInPlanDetailssdvdfverfd(id) {
  return getApi().get('home/upcomingAndMovein/details/' + id);
}

import { getApi } from "../../services/httpService";


export function availableBookingInfo(id){
    return getApi().get('home/availableLot/'+id);
}

export function similarBookingInfo(id){
    return getApi().get('home/similarLot/'+id);
}

export function bookingDetails(id){
    return getApi().get('home/floorPlan/comunites/'+id);
}

export function bookCreate(data){
    // console.log(data);
    return getApi().post('BookNow/create',data);
}

export function bookButtonSettings(){
    return getApi().get('settings/status');
}
import { getApi } from "../../services/httpService";


export function homePageSlider(){
    return getApi().get('home/page/slider');
}


export function moveInReadyHomes(){
    return getApi().get('home/floorPlan');
}


export function upcomingCommunities(){
    return getApi().get('home/upcoming/list');
}


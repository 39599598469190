import React, { Fragment, Component } from 'react';
import Header from './../../Layout/Header';
import Footer2 from './../../Layout/Footer2';
import PageTitle from './../../Layout/PageTitle';
import Mapview from './../../Element/Mapview';
import EventSection from '../Homepage/EventSection';
// import SwiperSlider from './SwiperSlider';
import './QuickMoves.css';
import WOW from 'wowjs';
import data from '../../../staticData/staticData.js';
import Fade from 'react-reveal/Fade';

//Images 
import bnr1 from './../../../images/amenities/bg/pic1.jpg';
import bnr2 from './../../../images/amenities/bg/pic2.jpg';
import bnr3 from './../../../images/amenities/bg/pic3.jpg';
import { Dropdown, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { cardButton, inputButton, inputButtonHover, inputButtonPadding } from '../../CommonStyle/CommonStyle';
import breadcrumbImage from '../../../images/banner.png';
import { city } from '../../../Api/Actions/city';
import { quickMoveDataInfo } from '../../../Api/Actions/quickMove';
import { floorIcon } from '../../../Api/Actions/floorplan';
import { cityMap } from '../../../Api/Actions/findHome';

const backgrounBlog = [
  { image: bnr1, title: 'Dive in To The Aquamarine waters of our swanky Swimming pool', },
  { image: bnr2, title: 'Temple', },
  { image: bnr3, title: 'Squash Court', },
];

class QuickMoves extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: data,
      sorting: 0,
      loader: false,
      isHover: false,
      cityInfo: '',
      latitude: '32.779167',
      longitude: '-96.808891',
    }
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    new WOW.WOW().init();
    this.selectCityList();
    let data = {
      f_city_info_id: this.state.cityInfo,
    }
    this.fetchFloorPlanIcon();
    this.fetchQuicMoveData(data);

  }

  fetchMap = (id) => {
    cityMap(id).then(response => {
      this.setState({
        mapInfo: response.data[0],
        latitude: response.data[0].latitude,
        longitude: response.data[0].longitude,
      })
      this.state.latitude = response.data[0].latitude;
      this.state.longitude = response.data[0].longitude;
    })
  }

  selectCityList = () => {
    city().then(response =>
      this.setState({
        city: response.data,
      })
    )
  }

  autoSearchInputChange = (e) => {
    const { name, value, checked } = e.target;

    this.setState({
      [name]: value
    })
    this.state[name] = value;

    let data = {
      f_city_info_id: this.state.cityInfo,
    }


    this.fetchQuicMoveData(data);
    if (this.state.cityInfo == "") {
      this.setState({
        latitude: '32.779167',
        longitude: '-96.808891',
      })
    } else {
      this.fetchMap(this.state.cityInfo);
    }

  }

  fetchQuicMoveData = (data) => {
    quickMoveDataInfo(data).then(response => {
      this.setState({
        quickMoveData: response.data,
      })
    })
  }

  fetchFloorPlanIcon = () => {
    floorIcon().then(response => {
      this.setState({
        floorPlanIcon: response.data,
      })
    })
  }

  handleChange = (e) => {
    this.setState({ sorting: e.target.value });
    this.state.sorting = e.target.value;
    if (e.target.value == 0) {

      this.setState({ loader: true })
      setTimeout(() => {
        this.setState({ quickMoveData: this.state.quickMoveData });
        this.setState({ loader: false })
      }, 1000)

    }
    else if (e.target.value == 1) {
      this.setState({ loader: true })
      setTimeout(() => {
        let lowtohigh = this.state.quickMoveData && this.state.quickMoveData.sort((a, b) => a.price - b.price).map(d => d);
        this.setState({ quickMoveData: lowtohigh })
        this.setState({ loader: false })
      }, 1000)

    }
    else if (e.target.value == 2) {
      this.setState({ loader: true })
      setTimeout(() => {
        let hightolow = this.state.quickMoveData && this.state.quickMoveData.sort((a, b) => b.price - a.price).map(d => d);
        this.setState({ quickMoveData: hightolow })
        this.setState({ loader: false })
      }, 1000)
    }
  }
  handleMouseEnter = () => {
    this.setState({
      isHover: true,
    })
  };
  handleMouseLeave = () => {
    this.setState({
      isHover: false,
    })
  };

  clearFilter = () => {
    this.state.cityInfo = "";
    let dataInfo = {
      f_city_info_id: this.state.cityInfo,
    }
    this.fetchQuicMoveData(dataInfo);
    this.state.latitude = "32.779167";
    this.state.longitude = "-96.808891";
  }
  render() {
    const imageLink = process.env.REACT_APP_IMAGE_URL;
    let cityList = [];
    let quickMoveData = [];
    let floorPlanIcon = [];
    if (this.state.hasOwnProperty('city')) {
      cityList = this.state.city;
    }
    if (this.state.hasOwnProperty('quickMoveData')) {
      quickMoveData = this.state.quickMoveData;
    }
    if (this.state.hasOwnProperty('floorPlanIcon')) {
      floorPlanIcon = this.state.floorPlanIcon;
    }
    const { isHover } = this.state;

    const MY_API = 'AIzaSyCc3zoz5TZaG3w2oF7IeR-fhxNXi8uywNk';

    let _url = `https://www.google.com/maps/embed/v1/place?key=${MY_API}&q=${this.state.latitude},${this.state.longitude}`;
    return (
      <Fragment>
        <Header />
        <div className="page-content bg-white">
          {/* <PageTitle motherMenu="QUICK MOVE-IN" activeMenu="QUICK MOVE-IN" breadcrumbImage={breadcrumbImage} /> */}
          {/* Section-1 End */}
          <section className="content-inner" style={{ marginTop: '100px' }} data-content="QUICK MOVE-IN">
            <div className="container">
              <div className="d-flex flex-column justify-content-center">
                <h2 className="double-rules wow fadeInDown" data-wow-duration='2s' data-wow-delay="0.4s">QUICK MOVE-IN HOMES</h2>
                <div className="entry-content text-center">
                  <p className="wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">
                    Choose from select Aspen Square Homes currently under construction or available now for a quicker move-in.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* <Mapview />	 */}

          <section className="content-inner" style={{ paddingBottom: '0px', paddingTop: '0px' }}>


            <div className="container-fluid">
              <div className="row flex-column-reverse flex-lg-row">
                <div className="col-md-12 col-lg-6 wow fadeInLeft mt-3" data-wow-duration='2s' data-wow-delay="0.4s" id="communitiesMap">
                  <iframe src={_url} className="map-view border-0 align-self-stretch rounded-sm" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="col-md-12 col-lg-6 wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">
                  <a className="btn btn-dark d-block d-lg-none d-md-block my-2 wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s" href="#communitiesMap">View Map <i class="fa fa-map-o"></i></a>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-2">
                        <Form>
                          <label className="font-weight-bold text-primary mt-2">SELECT YOUR CITY</label>
                          <div className="input-group">
                            <select className="custom-select" id="inputGroupSelect04" name="cityInfo" aria-label="Example select with button addon" value={this.state.cityInfo} onChange={(e) => this.autoSearchInputChange(e)}>
                              <option value="">Any</option>
                              {
                                cityList && cityList.map(info =>
                                  <option value={info.id}>
                                    {info.city_name}
                                  </option>
                                )
                              }
                              {/* <option value="1">Irving</option>
                                                            <option value="2">Rowlett</option>
                                                            <option value="3">Garland</option>
                                                            <option value="4">Princeton</option>
                                                            <option value="5">McKinney</option> */}
                            </select>
                            {/* <div className="input-group-append">
                                                            <button className="btn btn-sm btn-primary" type="submit" style={ isHover == true ? (inputButtonHover('#569539','#fff','#fff'), inputButtonPadding()) : (inputButton('#000','#569539','#569539'),inputButtonPadding()) }  onMouseOver={()=>this.handleMouseEnter()} onMouseLeave={()=>this.handleMouseLeave()}>FIND YOUR HOME</button>
                                                        </div> */}
                          </div>
                        </Form>
                      </div>
                      <button class="btn btn-sm btn-primary mb-2 rounded-0" id="clearAll" style={isHover == true ? (inputButtonHover('#569539', '#fff', '#fff'), inputButtonPadding()) : (inputButton('#000', '#569539', '#569539'), inputButtonPadding())} onMouseOver={() => this.handleMouseEnter()} onMouseLeave={() => this.handleMouseLeave()} onClick={() => this.clearFilter()}>Clear Filters <i class="fa fa-refresh"></i></button>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-3 col-sm-4">
                          <p className="mt-2" style={{ fontSize: '14px' }}>SORT BY:</p>
                        </div>
                        <div className="Col-md-9 col-sm-8">
                          <select className="custom-select" id="inputGroupSelect04" name="sorting" aria-label="select-button" style={{ fontSize: '14px' }} value={this.state.sorting} onChange={this.handleChange}>
                            <option value={0}>UNSORTED</option>
                            <option value={1}>PRICE, LOW TO HIGH</option>
                            <option value={2}>PRICE, HIGH TO LOW</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <p style={{ fontSize: '14px' }} className="float-right"><span className="font-weight-bold" >MATCHING RESULTS: </span> <span>{quickMoveData.length} Home</span></p>
                    </div>

                  </div>
                  <div className="row" id="homeResults">
                    {this.state.loader == true ?
                      <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                        <div class="spinner-border text-primary" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div> :
                      quickMoveData.length == 0 ?
                        <div className="col-lg-12 col-md-12 col-sm-12 ">
                          <h5 className="text-danger text-center">No Communities found matching this search.</h5>
                        </div> :
                        quickMoveData && quickMoveData.map((f, index) =>
                          <div className="col-lg-6 col-md-6 col-sm-12 ">
                            <Fade bottom>
                              <div className="card" key={index}>
                                <div>
                                  <Link to={`/floor-plans/${f.slug}`}>
                                    <h5 className="text-center p-2 mb-0 font-weight-bold text-white bg-dark text-uppercase">{f.floore_plans_names}</h5>
                                    <img src={imageLink + 'FloorPlan/' + f.feature_image[0].f_project_names_id + '/Slider/thumbnalie/' + f.feature_image[0].slider_large_image} className="card-img-top" alt="..." />
                                    <p className="old-ribbon mb-0"><Link to={`/floor-plans/${f.slug}`} className="text-white text-uppercase">Ready Now</Link></p>
                                  </Link>
                                </div>
                                <div className="card-body p-2 card-body-design">
                                  <div>
                                    <h5 className="text-center"><Link to={`/floor-plans/${f.slug}`}>{f.address}</Link> <i className="fa fa-location-arrow"></i></h5>
                                    <div className="col-md-12 d-flex justify-content-center px-0">
                                      <table className="table table-bordered">
                                        <tbody>
                                          <tr>
                                            <td>

                                              <img className="icon" src="https://cbjenihomes.com/wp-content/uploads/2020/06/floorplan-icon.svg" alt="" />
                                              <span className="icon-title ml-2">Naples</span>
                                            </td>
                                            <td>
                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Size").map(floor =>
                                                <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                              )
                                              }
                                              <span className="icon-title ml-2">{f.size_details_sq_fit} Sq. Ft.</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Stories").map(floor =>
                                                <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                              )
                                              }
                                              <span className="icon-title ml-2">{f.stories_qty} Stories</span>
                                            </td>
                                            <td>
                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bedroom").map(floor =>
                                                <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                              )
                                              }
                                              <span className="icon-title ml-2">{f.bedroom_qty} Beds</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Garage").map(floor =>
                                                <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                              )
                                              }
                                              <span className="icon-title ml-2">{f.garage_qty} Car Garage</span>
                                            </td>
                                            <td>
                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                              )
                                              }
                                              <span className="icon-title ml-2">{f.bathroom_qty} Baths</span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mt-2">
                                      <span className="text-dark price-title font-weight-bold">${f.price}</span>
                                      <a className="text-dark" href="#"><span className="price-title font-weight-bold">Save <i className="fa fa-heart-o"></i></span></a>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer p-0">
                                  <button className="btn btn-primary w-100 rounded-0 py-1" style={cardButton('#000', '#fff', '#569539')} onClick={() => this.props.history.push(`/floor-plans/${f.slug}`)}>View Floor Plans</button>
                                </div>
                              </div>
                            </Fade>
                          </div>
                        )
                    }

                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer2 />
      </Fragment>
    )
  }
}

export default QuickMoves;
import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import SimpleReactLightbox from 'simple-react-lightbox';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import Header from './../../Layout/Header';
import Footer2 from './../../Layout/Footer2';
import PageTitle from './../../Layout/PageTitle';
//images
import bgimg from './../../../images/bg-view.png';
import Merritt_Villas_Site_Plan from './../../../assets/merrittVillas/Merritt_Villas_Site_Plan.webp';
import Merritt_Villas_Brochure from './../../../assets/merrittVillas/Merritt_Villas_Brochure.png';
import port1 from './../../../images/portfolio/portfolio-box/pic1.jpg';
import port2 from './../../../images/portfolio/portfolio-box/pic2.jpg';
import port3 from './../../../images/portfolio/portfolio-box/pic3.jpg';
import port4 from './../../../images/portfolio/portfolio-box/pic4.jpg';
import port5 from './../../../images/portfolio/portfolio-box/pic5.jpg';
import port6 from './../../../images/portfolio/portfolio-box/pic6.jpg';
import port7 from './../../../images/portfolio/portfolio-box/pic7.jpg';
import { ExhibitionSection, ExhibitionSection2 } from '../CompanyExhibition';
import ExhibitionSlider2 from '../../Element/ExhibitionSlider2';
import './portfolio.css';
import { Modal } from 'react-bootstrap';
import FooterContact from './FooterContact';
import breadcrumbImage from '../../../images/banner.png';


//Light Gallery on icon click 
/* const Iconimage = props => {
    const { openLightbox } = useLightbox()
	
  return (
    
  )
} */

const GalleryBox = ({ imageBlog }, props) => {
    const { openLightbox } = useLightbox()
    return (

        <div className="dlab-media dlab-img-overlay1 overlay-primary" >
            <img src={imageBlog} alt="" />
            <div className="overlay-bx">
                <div className="overlay-icon">
                    <a href="https://www.youtube.com/watch?v=Dj6CKxQue7U" className="popup-youtube">
                        <i className="fa fa-play icon-bx-xs"></i>
                    </a>
                    <a onClick={() => openLightbox(props.imageToOpen)} className=" " >
                        <i className="fa fa-search icon-bx-xs"></i>
                    </a>
                </div>
            </div>
        </div>
    );
};

class Portfolio3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleSwitch: false,
            isOpen1: false,
            isOpen2: false,
            isOpen3: false,
            isOpen4: false,
            loading: true,
        };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }
    componentDidMount() {
        new WOW.WOW().init();
    }
    openModal1 = () => this.setState({ isOpen1: true });
    closeModal1 = () => this.setState({ isOpen1: false });
    openModal2 = () => this.setState({ isOpen2: true });
    closeModal2 = () => this.setState({ isOpen2: false });
    openModal3 = () => this.setState({ isOpen3: true });
    closeModal3 = () => this.setState({ isOpen3: false });
    openModal4 = () => this.setState({ isOpen4: true });
    closeModal4 = () => this.setState({ isOpen4: false });

    // removeBodyCss() {
    // 	document.body.classList.add("no_padding");
    // }

    render() {
        return (
            <Fragment>
                <Header />
                <div className="page-content bg-white">
                    {/*  banner  */}
                    <PageTitle motherMenu="Merritt Villas TownHomes" activeMenu="Merritt Villas TownHomes"breadcrumbImage={breadcrumbImage} />
                    {/*  Section-1 Start  */}
                    <section className="content-inner" data-content="MERRITT VILLAS">
                        <div className="container">
                            <div className="d-flex flex-column justify-content-center wow fadeInDown" data-wow-duration='2s' data-wow-delay="0.2s">
                                <h2 className="double-rules">Merritt Villas Homes</h2>
                            </div>
                        </div>
                        <div className="container">
                            <section className="content-inner exhibition-bx">
                                <ExhibitionSection2 />
                            </section>
                            {/* <div className="section-head text-center">
							<p>VIEW PORTFOLLO</p>
							<h2 className="title">Don’t miss your chance to discover the most fascinating </h2>
							<p>Phasellus dolor risus, luctus at lorem eu, sollicitudin mollis leo. Pellentesque tincidunt vel tortor et cursus. Donec posuere risus vitae viverra cursus. Nunc porttitor pharetra elit id</p>
						</div>
						<SimpleReactLightbox>
							<SRLWrapper >
								<div className="row" id="lightgallery">
									<div className="col-md-12 col-lg-6">
										<div className="dlab-box gallery-box-1 m-b30 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s">
											<GalleryBox imageBlog={port1} />
										</div>
									</div>
									<div className="col-md-6 col-lg-3 col-sm-6">
										<div className="dlab-box gallery-box-1 m-b30 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.4s">
											<GalleryBox imageBlog={port2} />
										</div>
										<div className="dlab-box gallery-box-1 m-b30 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.6s">
											<GalleryBox imageBlog={port3} />
										</div>
									</div>
									<div className="col-md-6 col-lg-3 col-sm-6">
										<div className="dlab-box gallery-box-1 m-b30 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.8s">
											<GalleryBox imageBlog={port4} />
										</div>
										<div className="dlab-box gallery-box-1 m-b30 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s">
											<GalleryBox imageBlog={port5} />
										</div>
									</div>
									<div className="col-md-6 col-lg-6 col-sm-6">
										<div className="dlab-box gallery-box-1 m-b30 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.4s">
											<GalleryBox imageBlog={port6} />
										</div>
									</div>
									<div className="col-md-6 col-lg-6 col-sm-6">
										<div className="dlab-box gallery-box-1 m-b30 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.6s">
											<GalleryBox imageBlog={port7} />
										</div>
									</div>
								</div>
							</SRLWrapper>
						</SimpleReactLightbox> */}
                        </div>
                    </section>
                    <section className="content-inner" style={{ backgroundColor: 'rgba(0, 0, 0, 0.20)' }}>
                        <div className="container">
                            <div className="d-flex flex-column justify-content-center">
                                <h2 className="double-rules wow fadeInDown" data-wow-duration="2s" data-wow-delay="0.4s">FLOOR PLANS</h2>
                            </div>

                            {/* <p className="text-center">This community is sold out</p> */}
                            <p className="text-center text-white wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">This community will coming soon</p>
                            {/* <div className="d-flex justify-content-center text-dark" style={{fontSize:'12px'}}>
								<i className="text-center mb-1">SAVE AND COMPARE HOMES AND FLOOR PLANS WITH YOUR ASPEN SQUARE ACCOUNT</i>
							</div> */}
                            {/* <div className="d-flex justify-content-center">
								<div>
									<button className="btn btn-primary w-100 rounded-0 text-uppercase py-2">Compare Now</button>
								</div>
							</div> */}
                        </div>
                    </section>
                    <section className="content-inner" style={{ backgroundColor: '#89afc9' }}>
                        <div className="container">
                            <div className="d-flex flex-column justify-content-center">
                                <h2 className="double-rules text-white wow fadeInDown" data-wow-duration="2s" data-wow-delay="0.4s">MOVE-IN READY HOMES</h2>
                            </div>
                            <p className="text-center text-white wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">This community will coming soon</p>

                        </div>
                    </section>
                    <section className="content-inner">
                        <div className="container">
                            <div className="d-flex flex-column justify-content-center">
                                <h2 className="double-rules wow fadeInDown" data-wow-duration='2s' data-wow-delay="0.4s">SITE MAP</h2>
                            </div>
                            <div className="d-flex justify-content-center wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.2s">
                                <img src={Merritt_Villas_Site_Plan} className="w-100 h-100 img-fluid" style={{ border: '10px solid #569539' }} />
                            </div>
                        </div>
                    </section>
                    <section className="content-inner" style={{ backgroundColor: 'rgba(0, 0, 0, 0.20)' }}>
                        <div className="container">
                            <div className="d-flex flex-column justify-content-center">
                                <h2 className="double-rules wow fadeInDown" data-wow-duration='2s' data-wow-delay="0.4s">Brochure</h2>
                            </div>
                            <div>
                                <div className="row wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">
                                    {/* <div className="col-md-2">
                                    </div> */}
                                    <div className="col-md-12">
                                        {/* <iframe
                                            src="https://aspensquarehomes.com/wp-content/uploads/2022/06/Merritt_Villas_Brochure.png"
                                            frameBorder="0"
                                            scrolling="none"
                                            height="550px"
                                            width="100%"
                                            style={{ border: '10px solid #a6611c' }}
                                        ></iframe> */}
                                        <div style={{ border: '10px solid #569539', height: "650px", overflowY: 'scroll' }}>
                                            <img src={Merritt_Villas_Brochure} className="w-100 img-fluid" />
                                        </div>
                                    </div>
                                    {/* <div className="col-md-2">
                                    </div> */}
                                </div>

                            </div>
                            {/* <p className="text-center">This community is sold out</p> */}

                            {/* <div className="d-flex justify-content-center text-dark" style={{fontSize:'12px'}}>
								<i className="text-center mb-1">SAVE AND COMPARE HOMES AND FLOOR PLANS WITH YOUR ASPEN SQUARE ACCOUNT</i>
							</div> */}
                            {/* <div className="d-flex justify-content-center">
								<div>
									<button className="btn btn-primary w-100 rounded-0 text-uppercase py-2">Compare Now</button>
								</div>
							</div> */}
                        </div>
                    </section>
                    {/* <section className="content-inner">
                        <div className="container">
                            <div className="section-full wow fadeInLeft" data-wow-duration='2s' data-wow-delay="0.4s">
                                <ExhibitionSlider2 />
                            </div>
                        </div>
                    </section> */}
                    <section className="content-inner">
                        <div className="container">
                            <div className="d-flex flex-column justify-content-center">
                                <h2 className="double-rules wow fadeInDown" data-wow-duration='2s' data-wow-delay="0.4s">Area Attraction</h2>
                            </div>

                            <div className="banner-map wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4085.852733269405!2d-96.76930768450012!3d32.926800383639545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c203909f8f8a9%3A0xaa873528b927871f!2s13151%20Emily%20Rd%20%23201%2C%20Dallas%2C%20TX%2075240%2C%20USA!5e1!3m2!1sen!2sbd!4v1661408648772!5m2!1sen!2sbd" style={{ border: "0", width: "100%", minHeight: "500px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3348.916818411398!2d-96.76931304914724!3d32.92679588083341!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c203909f8f8a9%3A0xaa873528b927871f!2s13151%20Emily%20Rd%20%23201%2C%20Dallas%2C%20TX%2075240%2C%20USA!5e0!3m2!1sen!2sbd!4v1660126523856!5m2!1sen!2sbd" className="border-0 align-self-stretch rounded-sm" style={{ border: "0", width: "100%", minHeight: "500px" }} allowfullscreen ></iframe> */}
                            </div>
                        </div>
                    </section>

                    <section className="content-inner">
                        <FooterContact />
                    </section>
                    {/*  Section-1 End  */}
                    <div>
                        <img src={bgimg} className="bg-view" alt="" />
                    </div>
                </div>
                <Footer2 />
            </Fragment>
        )
    }
}
export { GalleryBox };
export default Portfolio3;
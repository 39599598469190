import { Component, Fragment } from "react";
import SideNav from "../../Element/SideNav";
import Footer2 from "../../Layout/Footer2";
import Header from "../../Layout/Header";
import PageTitle from "../../Layout/PageTitle";
import Detail3Owl from "../Aboutus/SponsorOwl";
import { ProDetail } from "../ProjectDetail/Data";
import WOW from 'wowjs';
import { Pagination } from "../Portfolio/Article";
import { Link } from "react-router-dom";
import noData from '../../../assets/common/noData.jpg';


const fakeSearchData = [
    {
        name: 'hello',
        link: '/about'
    },
    {
        name: 'aspen square townhomes',
        link: '/aspen-square-townhomes'
    },
    {
        name: 'gilbert villas townhomes',
        link: '/gilbert-villas-townhomes'
    },
    {
        name: 'merritt villas townhomes',
        link: '/merritt-villas-townhomes'
    },


]


class Searching extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: [...fakeSearchData]
        }
    }

    componentDidMount() {
        new WOW.WOW().init();
        let urlSearch = window.location.href.split('/').pop();

        const results = this.state.search.filter((user) => {
            return user.name.toLowerCase().startsWith(urlSearch.toLowerCase());

        });

        this.setState({
            search: [...results]
        })



    }

    render() {

        let searchURL = window.location.href.split('/').pop();

        const { search } = this.state;

        return (
            <Fragment>
                <Header />
                <div className="page-content bg-white" >
                    {/*  banner  */}
                    <PageTitle motherMenu="Search" activeMenu="Search" />

                    <section className="section-full content-inner">
                        <div className="container">
                            <div className="d-flex justify-content-center">
                                <h6>Search Results for:</h6>
                            </div>
                            <div className="d-flex flex-column justify-content-center">
                                <h2 className="double-rules">{searchURL}</h2>
                            </div>
                            {
                                search && search.length == 0 ?
                                    <div className="text-center">
                                        <img src={noData} alt="notification pic" className="img-responsive img-fluid w-25 h-25"/>
                                    </div> :
                                    <div className="text-center">
                                        <ul className="font-weight-bold text-primary">
                                            {
                                               search?.map((data, index) =>
                                                    <li key={index} style={{fontSize:'25px',}}><Link to={data.link} style={{color:'#569539'}}>{data.name}</Link></li>
                                                )
                                            }
                                        </ul>
                                    </div>
                            }

                            {/* <nav aria-label="Page navigation example justify-content-start">
                                <Pagination />
                            </nav> */}
                        </div>
                    </section>
                    {/*  Project Info  End*/}
                </div>
                <Footer2 />
            </Fragment>
        )
    }
}

export default Searching;
import React, { Component, Fragment } from 'react';
import { companyInfo } from '../../Api/Actions/companyInfo';
import { communitiesInfo } from '../../Api/Actions/coummnities';
import { footerBg, footerBottomBg } from '../CommonStyle/CommonStyle';
import { Footercol1, Footercol2, Footercol3, Footerbottom } from './Footer';

class Footer2 extends Component {
	constructor(props) {
		super(props);
		this.state={};

	}

	componentDidMount() {
		this.fetchCommunitiesInfo();
		this.fetchCompanyInfo();
	}
	fetchCommunitiesInfo = () => {
		communitiesInfo().then(response => {
			this.setState({
				communitiesInfo: response.data,
			})
		})
	}
	fetchCompanyInfo = () => {
		companyInfo().then(response => {
			this.setState({
				companyInfo: response.data,
			})
		})
	}
	render() {
		const imageLink = process.env.REACT_APP_IMAGE_URL;
		let companyInfo=[];
		let communitiesInfo = [];
		if (this.state.hasOwnProperty('communitiesInfo')) {
			communitiesInfo = this.state.communitiesInfo;
		}

		if (this.state.hasOwnProperty('companyInfo')) {
			companyInfo = this.state.companyInfo[0];
		}
		return (
			<Fragment>
				<footer className="site-footer" id="footer">
					<div className="footer-top" style={footerBg('#000') } >
						<div className="container">
							<div className="row">
								<div className="col-md-4">
									<Footercol1 imageLink={imageLink&&imageLink} companyInfo={companyInfo&&companyInfo}/>
								</div>
								<div className="col-md-4">
									<Footercol3 communitiesInfo={communitiesInfo && communitiesInfo}/>
								</div>
								<div className="col-md-4">
									<Footercol2 companyInfo={companyInfo&&companyInfo}/>
								</div>

							</div>
						</div>
					</div>
					{/*  footer bottom part */}
					<div className="footer-bottom" style={footerBottomBg('#000')}>
						<Footerbottom companyInfo={companyInfo&&companyInfo}/>
					</div>
				</footer>
			</Fragment>
		)
	}
}



export default Footer2;
import { Component } from "react";
import { Link } from "react-router-dom";
import WOW from 'wowjs';
import { cardButton, cardButtonHover } from "../../CommonStyle/CommonStyle";

class FooterContact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHover: false,
        }
    }

    componentDidMount() {
        new WOW.WOW().init();
    }

    handleMouseEnter = () => {
        this.setState({
            isHover: true,
        })
    };
    handleMouseLeave = () => {
        this.setState({
            isHover: false,
        })
    };

    render() {
        let url = window.location.pathname.split('/').pop();
        const { isHover } = this.state;
        const imageLink = process.env.REACT_APP_IMAGE_URL;


        const { sub_com_details } = this.props;
        // let communityInfo =  sub_com_details;
        // console.log(sub_com_details)
        return (
            <div className="container">
                <div className="d-flex flex-column justify-content-center">
                    <h2 className="double-rules wow fadeInDown" data-wow-duration='2s' data-wow-delay="0.4s">COMMUNITY SALES INFORMATION</h2>
                </div>
                {/* <div className="row">
                    <div className="col-md-4 wow fadeInLeft" data-wow-duration='2s' data-wow-delay="0.4s">
                        <div className="card">
                            {sub_com_details != undefined ?
                                <img src={imageLink + 'company_settings/aboutus/sub/project_logo/' + sub_com_details[0].project_logo} className="card-img-top" alt="..." />
                                : ''
                            }
                            <div className="card-body rounded-0" style={{ backgroundColor: "rgba(0, 0, 0, 0.1" }}>
                                <h5 className="card-title text-uppercase">Aspen Square</h5>
                                <hr className="text-dark border-1" />
                                <i className="card-text">Community Sales Manager</i>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s" >
                        <div className="bg-dark" style={{ height: '180px ' }}>
                            <h4 className="text-center text-white pt-2">
                                <u className="text-white">Visit Our Community Sales Office</u>
                            </h4>
                            {
                                url == "aspen-square-townhomes" ? <p className="text-center text-white" style={{ fontSIze: '8px' }}>
                                   13151 Emily Rd, Suite 201, Dallas, TX-75240
                                </p> : url == "merritt-villas-townhomes" ? <p className="text-center text-white" style={{ fontSIze: '8px' }}>
                                    13151 Emily Rd, Suite 201, Dallas, Texas - 75240
                                </p> : url == "gilbert-villas-townhomes" ? <p className="text-center text-white" style={{ fontSIze: '8px' }}>
                                    Gilbert Villas, 108 E Shady Grove Irving, TX 75060
                                </p> : 
                            <p className="text-center text-white" style={{ fontSIze: '8px' }}>
                                {sub_com_details != undefined ?sub_com_details[0].contact_information_description:''}
                            </p>
                             } 

                            <p className="text-center text-white">Monday – Thursday : 10AM to 6PM , Friday – Saturday : 11AM to 4PM, Sunday : Close</p>
                        </div>
                        <div className="mt-2 bg-dark" style={{ height: '150px ' }}>
                            <h4 className="text-center text-white pt-2">
                                <u className="text-white">Reach Out Now</u>
                            </h4>
                            <div className="d-flex d- justify-content-center">
                                <Link to="/contact-us"><button className="btn btn-sm btn-outline-light rounded-0 mx-1"><i className="fa fa-calendar"></i> Book Now</button></Link>
                                <button className="btn btn-sm btn-outline-light rounded-0 mx-1"><i className="fa fa-phone"></i> {sub_com_details != undefined ? sub_com_details[0].project_phone_no:''}</button>
                                <Link to="/contact-us"><button className="btn btn-sm btn-outline-light rounded-0 mx-1"><i className="fa fa-envelope"></i> Contact Us</button></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 m-b30 mt-2 wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.6s">
                        <div className="section-head">
                            <form className="contact-box dzForm p-a30 border-1" action="script/contact.php">
                                <h3 className="title-box">REQUEST MORE INFORMATION</h3>
                                <div className="dzFormMsg m-b20"></div>
                                <input type="hidden" value="Contact" name="dzToDo" />
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <input name="dzFirstName" type="text" required="" className="form-control" placeholder="First Name*" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <input name="dzLastName" type="text" required="" className="form-control" placeholder="Last Name*" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <input name="dzEmail" type="email" className="form-control" required="" placeholder="Enter Address*" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <input name="dzOther[Phone]" type="text" required="" className="form-control" placeholder="Phone Number*" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <input name="dzZipCode" type="text" className="form-control" required="" placeholder="Zip Code*" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <textarea name="dzMessage" rows="4" className="form-control" required="" placeholder="Question"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group form-recaptcha">
                                            <div className="input-group">
                                                <div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
                                                <input className="form-control d-none" style={{ display: "none" }} data-recaptcha="true" required data-error="Please complete the Captcha" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <button name="submit" type="submit" value="Submit" className="btn btn-primary btn-lg" style={isHover == true ? cardButtonHover('#569539', '#fff', '#fff') : cardButton('#000', '#fff', '#569539')} onMouseOver={() => this.handleMouseEnter()} onMouseLeave={() => this.handleMouseLeave()}>Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <Link to={"/contact-us-1"} className="btn btn-primary m-r10 m-b10">Contact us</Link>
						<Link to={"/portfolio-1"} className="btn btn-primary m-b10">View Portfolio</Link>
                    </div>
                </div> */}

            </div>
        )
    }
}

export default FooterContact;
import React,{Fragment, Component,useState, useEffect} from 'react'; 
import {Link} from 'react-router-dom';
import WOW from 'wowjs';
import Masonry from 'react-masonry-component';
import Header from './../../Layout/Header';
import Footer2 from './../../Layout/Footer2';
import PageTitle from './../../Layout/PageTitle';

import breadcrumbImage from '../../../images/banner.png';
import error from '../../../assets/Error/404ERROR.jpg';
import ErrorPage from '../../Layout/ErrorPage';

class NoMatch extends Component{
	componentDidMount(){
		new WOW.WOW().init();	
	}
	render(){
		let url = window.location.href.split('/').pop();
        // console.log('url', this.props.);
		
		return(
			<Fragment>	
			<Header />
			<div className="page-content bg-white">

				<ErrorPage breadcrumbImage={breadcrumbImage}/>
					<section className="content-inner-1"  style={{minHeight:'80vh'}}>				
						<div className="container">
							<div className="error-page text-center">
								<div className="dz_error wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s"><img src={error} className="img-fluid w-100 h-50 mt-3"/></div>
								{/* <h5 className="mt-5 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">Construction work has begun, more details are coming soon!....</h5> */}
								<div className="m-b30">
								</div>
								<Link to={"/"} className="btn btn-primary btn-lg wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">Return to Home</Link>
							</div>
						</div>
					</section>
				
			</div>	
			<Footer2 />
			</Fragment>			
		)
	}
} 

export default NoMatch;
import { getApi } from "../../services/httpService";


export function price(){
    return getApi().get('home/filterValue/price');
}

export function bedRoom(){
    return getApi().get('home/filterValue/bedroom');
}

export function bathRoom(){
    return getApi().get('home/filterValue/bathrooms');
}

export function stories(){
    return getApi().get('home/filterValue/stories');
}

export function size(){
    return getApi().get('home/filterValue/size');
}

export function garage(){
    return getApi().get('home/filterValue/garage');
}

export function district(){
    return getApi().get('home/filterValue/district');
}

export function floorPlanList(){
    return getApi().get('home/filterValue/floorPlanList');
}


export function filterDataInfo(data){
    return getApi().post('home/filterValue/floorPlanList/filter',data);
}

export function cityMap(id){
    return getApi().get('home/city/'+id);
}






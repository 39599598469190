import React, { Fragment, Component } from 'react';
import Header from './../../Layout/Header';
import Footer2 from './../../Layout/Footer2';
import PageTitle from './../../Layout/PageTitle';
import Mapview from './../../Element/Mapview';
import EventSection from '../Homepage/EventSection';
// import SwiperSlider from './SwiperSlider';
import './FindHome.css';
import WOW from 'wowjs';
import data from '../../../staticData/staticData.js';
//Images 
import bnr1 from './../../../images/amenities/bg/pic1.jpg';
import bnr2 from './../../../images/amenities/bg/pic2.jpg';
import bnr3 from './../../../images/amenities/bg/pic3.jpg';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { city } from '../../../Api/Actions/city';
import { cardButton, cardButtonHover } from '../../CommonStyle/CommonStyle';
import breadcrumbImage from '../../../images/banner.png';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import MyFancyComponent from "./Map";
import Fade from 'react-reveal/Fade';
import { bathRoom, bedRoom, cityMap, district, filterDataInfo, floorPlanList, garage, price, size, stories } from '../../../Api/Actions/findHome';
import { floorIcon } from '../../../Api/Actions/floorplan';
const backgrounBlog = [
  { image: bnr1, title: 'Dive in To The Aquamarine waters of our swanky Swimming pool', },
  { image: bnr2, title: 'Temple', },
  { image: bnr3, title: 'Squash Court', },
];

class FindHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      city: [],
      isHover: false,
      sorting: 0,
      data: data,
      loader: false,
      cityInfo: '',
      quick_move: false,
      price: '',
      bedrooms: '',
      bathrooms: '',
      school: '',
      floorPlan: '',
      stories: '',
      size: '',
      garage: '',
      filterData: [],
      latitude: '32.779167',
      longitude: '-96.808891',
    }
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    new WOW.WOW().init();
    this.selectCityList();
    this.fetchPriceList();
    this.fetchBedRoomList();
    this.fetchBathroomList();
    this.fetchStoriesList();
    this.fetchSizeList();
    this.fetchGarageList();
    this.fetchDistrictList();
    this.fetchFloorPlanList();
    this.fetchFloorPlanIcon();

    if (this.props.location.state) {
      const { data } = this.props.location.state;
      this.setState({
        cityInfo: data.cityInfo,
        quick_move: data.quick_move,
      })

      if (data.cityInfo == "") {
        this.setState({
          latitude: '32.779167',
          longitude: '-96.808891',
        })
      } else {
        this.fetchMap(data.cityInfo)
      }

      let dataInfo = {
        f_city_info_id: data.cityInfo
      }
      this.fetchFilterData(dataInfo)
    }
  }

  fetchMap = (id) => {
    cityMap(id).then(response => {
      this.setState({
        mapInfo: response.data[0],
        latitude: response.data[0].latitude,
        longitude: response.data[0].longitude,
      })
      this.state.latitude = response.data[0].latitude;
      this.state.longitude = response.data[0].longitude;
    })
  }

  selectCityList = () => {
    city().then(response =>
      this.setState({
        city: response.data,
      })
    )
  }

  fetchPriceList = () => {
    price().then(response =>
      this.setState({
        priceList: response.data,
      })
    )
  }

  fetchBedRoomList = () => {
    bedRoom().then(response =>
      this.setState({
        bedRoomList: response.data,
      })
    )
  }

  fetchBathroomList = () => {
    bathRoom().then(response =>
      this.setState({
        bathRoomList: response.data,
      })
    )
  }

  fetchStoriesList = () => {
    stories().then(response =>
      this.setState({
        storiesList: response.data,
      })
    )
  }

  fetchSizeList = () => {
    size().then(response =>
      this.setState({
        sizeList: response.data,
      })
    )
  }

  fetchGarageList = () => {
    garage().then(response =>
      this.setState({
        garageList: response.data,
      })
    )
  }

  fetchDistrictList = () => {
    district().then(response =>
      this.setState({
        districtList: response.data,
      })
    )
  }

  fetchFloorPlanList = () => {
    floorPlanList().then(response =>
      this.setState({
        floorPlanList: response.data,
      })
    )
  }

  fetchFilterData = (data) => {
    // console.log(data);
    filterDataInfo(data).then(response => {
      this.setState({
        filterData: response.data,
      })
      if (this.state.quick_move == true) {
        let moveHomes = [];
        moveHomes = this.state.filterData.filter(p => p.is_quick_move_in_homes == 1).map(f => f);
        this.setState({
          filterData: moveHomes,
        })
        this.state.filterData = moveHomes
      }
    })
  }


  handleMouseEnter = () => {
    this.setState({
      isHover: true,
    })
  };
  handleMouseLeave = () => {
    this.setState({
      isHover: false,
    })
  };

  autoSearchInputChange = (e) => {
    const { name, value, checked } = e.target;

    this.setState({
      [name]: value
    })
    this.state[name] = value;

    if (name == "quick_move") {
      this.setState({
        quick_move: checked
      })
      this.state.quick_move = checked;


    }

    if (this.state.cityInfo == "") {
      this.setState({
        latitude: '32.779167',
        longitude: '-96.808891',
      })
    } else {
      this.fetchMap(this.state.cityInfo);
    }

    this.setState({ loader: true })

    setTimeout(() => {
      this.setState({ filterData: this.state.filterData });
      this.setState({ loader: false })
    }, 1000)

    if (this.state.quick_move == true) {
      if (this.state.quick_move == true) {
        let moveHome = [];
        moveHome = this.state.filterData.filter(p => p.is_quick_move_in_homes != 0).map(f => f);
        this.setState({
          filterData: moveHome,
        })
        this.state.filterData = moveHome
      }
    } else {
      let data = {
        f_bedroom_id: this.state.bedrooms,
        f_garage_id: this.state.garage,
        f_bathroom_id: this.state.bathrooms,
        f_size_id: this.state.size,
        f_city_info_id: this.state.cityInfo,
        f_district_id: this.state.school,
        f_floor_plane_id: this.state.floorPlan,
        f_stories_id: this.state.stories,
        f_price_id: this.state.price,
      }
      this.fetchFilterData(data);
    }

  }

  fetchFloorPlanIcon = () => {
    floorIcon().then(response => {
      this.setState({
        floorPlanIcon: response.data,
      })
    })
  }

  handleChange = (e) => {
    this.setState({ sorting: e.target.value });
    this.state.sorting = e.target.value;
    if (e.target.value == 0) {
      this.setState({ loader: true })
      setTimeout(() => {
        this.setState({ filterData: this.state.filterData });
        this.setState({ loader: false })
      }, 1000)

    }
    else if (e.target.value == 1) {
      this.setState({ loader: true })
      setTimeout(() => {
        let lowtohigh = this.state.filterData && this.state.filterData.sort((a, b) => a.price - b.price).map(d => d);
        this.setState({ filterData: lowtohigh })
        this.setState({ loader: false })
      }, 1000)

    }
    else if (e.target.value == 2) {
      this.setState({ loader: true })
      setTimeout(() => {
        let hightolow = this.state.filterData && this.state.filterData.sort((a, b) => b.price - a.price).map(d => d);
        this.setState({ filterData: hightolow })
        this.setState({ loader: false })
      }, 1000)
    }
  }


  clearFilter = () => {
    this.state.bedrooms = "";
    this.state.garage = "";
    this.state.bathrooms = "";
    this.state.size = "";
    this.state.cityInfo = "";
    this.state.school = "";
    this.state.floorPlan = "";
    this.state.stories = "";
    this.state.price = "";
    this.state.quick_move = false;
    let dataInfo = {
      f_bedroom_id: "",
      f_garage_id: "",
      f_bathroom_id: "",
      f_size_id: "",
      f_city_info_id: "",
      f_district_id: "",
      f_floor_plane_id: "",
      f_stories_id: "",
      f_price_id: "",
    }
    this.fetchFilterData(dataInfo);
    this.state.latitude = "32.779167";
    this.state.longitude = "-96.808891";
  }

  render() {
    const imageLink = process.env.REACT_APP_IMAGE_URL;

    let cityList = [];
    let priceList = [];
    let bedRoomList = [];
    let bathRoomList = [];
    let storiesList = [];
    let sizeList = [];
    let garageList = [];
    let districtList = [];
    let floorPlanList = [];
    let filterDataInfo = [];
    let floorPlanIcon = [];
    let mapInfo = [];
    if (this.state.hasOwnProperty('city')) {
      cityList = this.state.city;
    }
    if (this.state.hasOwnProperty('priceList')) {
      priceList = this.state.priceList;
    }
    if (this.state.hasOwnProperty('bedRoomList')) {
      bedRoomList = this.state.bedRoomList;
    }
    if (this.state.hasOwnProperty('bathRoomList')) {
      bathRoomList = this.state.bathRoomList;
    }
    if (this.state.hasOwnProperty('storiesList')) {
      storiesList = this.state.storiesList;
    }
    if (this.state.hasOwnProperty('sizeList')) {
      sizeList = this.state.sizeList;
    }
    if (this.state.hasOwnProperty('garageList')) {
      garageList = this.state.garageList;
    }
    if (this.state.hasOwnProperty('districtList')) {
      districtList = this.state.districtList;
    }
    if (this.state.hasOwnProperty('floorPlanList')) {
      floorPlanList = this.state.floorPlanList;
    }

    if (this.state.hasOwnProperty('filterData')) {
      filterDataInfo = this.state.filterData;
    }

    if (this.state.hasOwnProperty('floorPlanIcon')) {
      floorPlanIcon = this.state.floorPlanIcon;
    }

    // let latitude = "32.8583";
    // let longitude = "-96.9702";

    if (this.state.hasOwnProperty('mapInfo')) {
      mapInfo = this.state.mapInfo;
    }


    const { isHover } = this.state;
    if (this.state.quick_move == true) {
      filterDataInfo.filter(p => p.is_quick_move_in_homes == 1).map(f => f);
    }

    const MY_API = 'AIzaSyCc3zoz5TZaG3w2oF7IeR-fhxNXi8uywNk';

    let _url = `https://www.google.com/maps/embed/v1/place?key=${MY_API}&q=${this.state.latitude},${this.state.longitude}`;


    return (
      <Fragment>
        <Header />
        <div className="page-content bg-white">
          {/* <PageTitle motherMenu="Find Home " activeMenu="Find Home" breadcrumbImage={breadcrumbImage} /> */}
          {/* Section-1 End */}
          <section className="content-inner" style={{ marginTop: '100px' }} data-content="FIND HOME">
            <div className="container">
              <div className="d-flex flex-column justify-content-center">
                <h2 className="double-rules wow fadeInDown" data-wow-duration='2s' data-wow-delay="0.4s">FIND YOUR HOME</h2>
              </div>
              <div className="m-0 pt-1 pb-3 px-3 px-lg-5 text-white wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s" style={{ background: '#000' }}>
                <div className="form-row d-flex align-items-end justify-content-center">
                  <div className="col-6 col-md-4 col-xl-20 mt-2">
                    <label for="price">Price</label>
                    <select id="price" className="custom-select custom-select-sm" name="price" value={this.state.price} onChange={(e) => this.autoSearchInputChange(e)}>
                      <option value="">Any</option>
                      {
                        priceList && priceList.map(info =>
                          <option value={info.id}>
                            {info.filter_value_number}
                          </option>
                        )
                      }
                    </select>
                  </div>
                  <div className="col-6 col-md-4 col-xl-20 mt-2">
                    <label for="bedrooms">Bedrooms</label>
                    <select id="bedrooms" className="custom-select custom-select-sm" name="bedrooms" value={this.state.bedrooms} onChange={(e) => this.autoSearchInputChange(e)}>
                      <option value="">Any</option>
                      {
                        bedRoomList && bedRoomList.map(info =>
                          <option value={info.id}>
                            {info.filter_value_number}
                          </option>
                        )
                      }
                    </select>
                  </div>
                  <div className="col-6 col-md-4 col-xl-20 mt-2">
                    <label for="bathrooms">Bathrooms</label>
                    <select id="bathrooms" className="custom-select custom-select-sm" name="bathrooms" value={this.state.bathrooms} onChange={(e) => this.autoSearchInputChange(e)}>
                      <option value="">Any</option>
                      {
                        bathRoomList && bathRoomList.map(info =>
                          <option value={info.id}>
                            {info.filter_value_number}
                          </option>
                        )
                      }
                    </select>
                  </div>
                  <div className="col-6 col-md-4 col-xl-20 mt-2">
                    <label for="city">City</label>
                    <select id="city" name="cityInfo" className="custom-select custom-select-sm" value={this.state.cityInfo} onChange={(e) => this.autoSearchInputChange(e)}>
                      <option value="">Any</option>
                      {
                        cityList && cityList.map(info =>
                          <option value={info.id}>
                            {info.city_name}
                          </option>
                        )
                      }
                    </select>
                  </div>
                  <div className="col-6 col-md-4 col-xl-20 mt-2">
                    <label for="school">School District</label>
                    <select id="school" className="custom-select custom-select-sm" name="school" value={this.state.school} onChange={(e) => this.autoSearchInputChange(e)}>
                      <option value="">Any</option>
                      {
                        districtList && districtList.map(info =>
                          <option value={info.district_id}>
                            {info.district_name}
                          </option>
                        )
                      }
                    </select>
                  </div>
                  <div className="col-6 col-md-4 col-xl-20 mt-2">
                    <label for="size">Size</label>
                    <select id="size" className="custom-select custom-select-sm" name="size" value={this.state.size} onChange={(e) => this.autoSearchInputChange(e)}>
                      <option value="">Any</option>
                      {
                        sizeList && sizeList.map(info =>
                          <option value={info.id}>
                            {info.filter_value_number}
                          </option>
                        )
                      }
                    </select>
                  </div>
                  <div className="col-6 col-md-4 col-xl-20 mt-2">
                    <label for="stories">Stories</label>
                    <select id="stories" className="custom-select custom-select-sm" name="stories" value={this.state.stories} onChange={(e) => this.autoSearchInputChange(e)}>
                      <option value="" >Any</option>
                      {
                        storiesList && storiesList.map(info =>
                          <option value={info.id}>
                            {info.filter_value_number}
                          </option>
                        )
                      }
                    </select>
                  </div>
                  <div className="col-6 col-md-4 col-xl-20 mt-2">
                    <label for="floorPlan">Floorplan</label>
                    <select id="floorPlan" className="custom-select custom-select-sm" name="floorPlan" value={this.state.floorPlan} onChange={(e) => this.autoSearchInputChange(e)}>
                      <option value="" >Any</option>
                      {
                        floorPlanList && floorPlanList.map(info =>
                          <option value={info.id}>
                            {info.floore_plans_names}
                          </option>
                        )
                      }
                    </select>
                  </div>
                  <div className="col-md-4 col-lg-2 col-xl-20 mt-2">
                    <div className="custom-control custom-checkbox  d-flex align-items-center">
                      {/* <input type="checkbox" className="mr-2" name="quick_move" value={this.state.quick_move} onChange={(e) => this.autoSearchInputChange(e)} id="customCheck1" checked={this.state.quick_move}/> */}

                      <input type="checkbox" className="custom-control-input" id="quickMoveIn" name="quick_move" value={this.state.quick_move} onChange={(e) => this.autoSearchInputChange(e)} checked={this.state.quick_move == true} />
                      <label className="custom-control-label" for="quickMoveIn">Show Quick Move-in Only</label>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-2 col-xl-20 mt-2 ml-auto text-center text-md-right">
                    <button className="btn btn-light btn-sm" id="clearAll" style={isHover == true ? cardButtonHover('#569539', '#fff', '#fff') : cardButton('#fff', '#000', '#fff')} onClick={() => this.clearFilter()} onMouseOver={() => this.handleMouseEnter()} onMouseLeave={() => this.handleMouseLeave()}>Clear Filters <i className="fa fa-refresh"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <Mapview />	 */}

          <section className="content-inner" style={{ paddingBottom: '0px', paddingTop: '0px' }}>


            <div className="container-fluid">
              <div className="row flex-column-reverse flex-lg-row">
                <div className="col-md-12 col-lg-6 wow fadeInLeft mt-3" data-wow-duration='2s' data-wow-delay="0.4s" id="communitiesMap">

                  {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4091.6035719560764!2d-96.993403!3d32.802062!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e83f1001ff70b%3A0x97cf96c462212470!2s920%20S%20Belt%20Line%20Rd%2C%20Irving%2C%20TX%2075060%2C%20USA!5e1!3m2!1sen!2sbd!4v1661672235250!5m2!1sen!2sbd" className="map-view border-0 align-self-stretch rounded-sm" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                  <iframe src={_url} className="map-view border-0 align-self-stretch rounded-sm" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  {/* <MyFancyComponent/> */}

                </div>
                <div className="col-md-12 col-lg-6 wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">
                  <a className="btn btn-primary d-block d-lg-none d-md-block my-2" href="#communitiesMap">View Map <i class="fa fa-map-o"></i></a>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-3 col-sm-4">
                          <p className="mt-2" style={{ fontSize: '14px' }}>SORT BY:</p>
                        </div>
                        <div className="Col-md-9 col-sm-8">
                          <select className="custom-select" id="inputGroupSelect04" name="sorting" aria-label="select-button" style={{ fontSize: '14px' }} value={this.state.sorting} onChange={this.handleChange}>
                            <option value={0}>UNSORTED</option>
                            <option value={1}>PRICE, LOW TO HIGH</option>
                            <option value={2}>PRICE, HIGH TO LOW</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <p style={{ fontSize: '14px' }} className="float-right"><span className="font-weight-bold" >MATCHING RESULTS: </span> <span>{filterDataInfo.length} Homes</span></p>
                    </div>

                  </div>
                  <div className="row" id="homeResultsWrap">
                    {this.state.loader == true ?
                      <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                        <div class="spinner-border text-primary" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div> :
                      filterDataInfo.length == 0 ?
                        <div className="col-lg-12 col-md-12 col-sm-12 ">
                          <h5 className="text-danger text-center">No Communities found matching this search.</h5>
                        </div>
                        :
                        filterDataInfo && filterDataInfo.map((f, index) =>
                          <div className="col-lg-6 col-md-6 col-sm-12 ">
                            <Fade bottom>
                              <div className="card" key={index}>
                                <div>
                                  <Link to={`/floor-plans/${f.slug}`}>
                                    <h5 className="text-center p-2 mb-0 font-weight-bold text-white bg-dark text-uppercase">{f.floore_plans_names}</h5>
                                    <img src={imageLink + 'FloorPlan/' + f.feature_image[0].f_project_names_id + '/Slider/thumbnalie/' + f.feature_image[0].slider_large_image} className="card-img-top" alt="..." />
                                    <p className="old-ribbon mb-0"><Link to={`/floor-plans/${f.slug}`} className="text-white text-uppercase">Ready Now</Link></p>
                                  </Link>
                                </div>
                                <div className="card-body p-2 card-body-design">
                                  <div>
                                    <h5 className="text-center"><Link to={`/floor-plans/${f.slug}`}>{f.address}</Link> <i className="fa fa-location-arrow"></i></h5>
                                    <div className="col-md-12 d-flex justify-content-center px-0">
                                      <table className="table table-bordered">
                                        <tbody>
                                          <tr>
                                            <td>

                                              <img className="icon" src="https://cbjenihomes.com/wp-content/uploads/2020/06/floorplan-icon.svg" alt="" />
                                              <span className="icon-title ml-2">Naples</span>
                                            </td>
                                            <td>
                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Size").map(floor =>
                                                <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                              )
                                              }
                                              <span className="icon-title ml-2">{f.size_details_sq_fit} Sq. Ft.</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Stories").map(floor =>
                                                <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                              )
                                              }
                                              <span className="icon-title ml-2">{f.stories_qty} Stories</span>
                                            </td>
                                            <td>
                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bedroom").map(floor =>
                                                <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                              )
                                              }
                                              <span className="icon-title ml-2">{f.bedroom_qty} Beds</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Garage").map(floor =>
                                                <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                              )
                                              }
                                              <span className="icon-title ml-2">{f.garage_qty} Car Garage</span>
                                            </td>
                                            <td>
                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                              )
                                              }
                                              <span className="icon-title ml-2">{f.bathroom_qty} Baths</span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mt-2">
                                      <span className="text-dark price-title font-weight-bold">${f.price}</span>
                                      <a className="text-dark" href="#"><span className="price-title font-weight-bold">Save <i className="fa fa-heart-o"></i></span></a>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer p-0">
                                  <button className="btn btn-primary w-100 rounded-0 py-1" style={cardButton('#000', '#fff', '#569539')} onClick={() => this.props.history.push(`/floor-plans/${f.slug}`)}>View Floor Plans</button>
                                </div>
                              </div>
                            </Fade>
                          </div>
                        )

                    }
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer2 />
      </Fragment>
    )
  }
}


export default FindHome;
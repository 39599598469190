import react, { Component } from "react";
import Carousel from 'react-bootstrap/Carousel';
import { Form, Button, Dropdown, Modal, Table } from 'react-bootstrap';
import { homePageVision, homePageVisionBg, inputButton, inputButtonHover, inputButtonPadding } from "../../../CommonStyle/CommonStyle";


class Vision extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;
        const {  companyInfo, ourVisionShortDesc, ourVisionDetails } = this.props;
        return (
            <section className="content-inner about-box" data-content="VISION" id="sidenav_aboutUs" style={homePageVisionBg('#000')}>
                <div className="about-bg" style={homePageVision(imageLink + 'company_settings/our_vision_image/' + companyInfo.our_vision_image)}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 col-lg-6">
                            <div className="section-head">
                                <h2 className="title wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s" style={{ color: '#569539 ' }}>{companyInfo.our_vision_title}</h2>
                                <div className="dlab-separator" ></div>
                                <h4 className="mb-4 wow fadeInUp vision-short-title" data-wow-duration="2s" data-wow-delay="0.4s" >{ourVisionShortDesc}</h4>
                                {/* <p className="text-white font-weight-bold wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s"> */}
                                {/* {ourVisionDetails.length > MAX_LENGTH ?
                                (
                                    <p className="text-white font-weight-bold wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                        {`${ourVisionDetails.substring(0, MAX_LENGTH)}...`} <span class="text-primary font-weight-bold" onClick={this.openModal2} style={{ cursor: 'pointer' }}>Read more</span>
                                    </p>
                                ) : */}
                                <p className="text-white font-weight-bold wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">{ourVisionDetails == undefined ? '' : ourVisionDetails}</p>
                                {/* } */}
                                {/* </p> */}

                                {/* <Modal ref={this.wrapper} show={this.state.isOpen2} size="lg" onHide={this.closeModal2} animation={true} centered scrollable>
                                <Modal.Body style={{ backgroundColor: '#569539' }}>
                                    <div className="row justify-content-center">
                                        <div className="col-md-12">
                                            <h2 className="title" style={{ color: '#000 ' }}>Our Vision</h2>
                                            <div className="dlab-separator" ></div>
                                            <h4 className="mb-4 " style={{ color: '#F8EDBF' }}>“Aspen Square” Where We Build Your Visions</h4>
                                            <p className="text-white font-weight-bold ">
                                                {content}
                                            </p>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal> */}
                            </div>

                        </div>
                        <div className="col-md-5 col-lg-6"></div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Vision;
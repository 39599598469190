import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import ModalImg from '../../assets/modalImg.png'
import ModalTrail from '../../assets/Projects/frame2865.jpg'
import "./reactModal.css"
class ReactModal extends Component {
  render() {
    const imageLink = process.env.REACT_APP_IMAGE_URL;
    const settings = {
      dots: false,
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true
    };
    return (
      <div>
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="my-modal"
        >
          <Modal.Body style={{ padding: "0" }} >
            <div>
              <Slider {...settings}>
                {
                  this.props.data?.map((res, index) => (
                    <>
                      <span onClick={this.props.onHide} className="cross_position">&#9587;</span>
                      <a href={`${res.promotion_url}`} target="_blank" rel="noreferrer">
                        <div key={index} className="d-flex flex-column justify-content-center ml-md-5 pl-md-5">
                          <div className='image_size'>
                            <img width="100%" height="100%" src={imageLink + 'Promotion/large/' + res.large_image} alt="..." />
                            <Button className='btn_size d-none d-md-block'>View Details</Button>
                          </div>
                          <Button className='btn_size_m d-block d-md-none'>View Details</Button>
                        </div>
                      </a>
                    </>
                  ))
                }
              </Slider>
            </div>
          </Modal.Body>
        </Modal>
      </div >
    );
  }
}

export default ReactModal;
import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import Header from './../Layout/Header';
import Footer2 from './../Layout/Footer2';
import PageTitle from './../Layout/PageTitle';
import ExhibitionSlider1 from './../Element/ExhibitionSlider1';
import ExhibitionSlider2 from './../Element/ExhibitionSlider2';
import breadcrumbImage from '../../images/banner.png';

//images/

class CompanyExhibition extends Component {
	componentDidMount() {
		new WOW.WOW().init();
	}
	render() {
		return (
			<Fragment>
				<Header />
				<div className="page-content bg-white">
					<PageTitle motherMenu="Company Exhibition" activeMenu="Company Exhibition" breadcrumbImage={breadcrumbImage} />
					{/*  Inner Page Banner */}
					<section className="content-inner exhibition-bx" data-content="EXHIBITION">
						<ExhibitionSection />
					</section>
					<div className="section-full">
						<ExhibitionSlider2 />
					</div>
				</div>
				<Footer2 />
			</Fragment>
		)
	}
}

function ExhibitionSection({communitiesDetails}) {

	return (
		<>
			<div className="container">
				<div className="row align-items-center wow fadeInLeft justify-content-center" data-wow-duration='2s' data-wow-delay="0.2s">
					<div className="col-lg-12 ">
						<ExhibitionSlider1 communitiesDetails={communitiesDetails} />
					</div>
				</div>
			</div>
		</>
	)
}
export { ExhibitionSection };
function ExhibitionSection2() {
	return (
		<>
			<div className="container">
			
				<div className="row align-items-center wow fadeInLeft" data-wow-duration='2s' data-wow-delay="0.4s">
					<div className="col-lg-12">
						<ExhibitionSlider1 />
					</div>
				</div>
			</div>
		</>
	)
}
export { ExhibitionSection2 };

function ExhibitionSection3() {
	return (
		<>
			<div className="container">
				<div className="row align-items-center wow fadeInLeft" data-wow-duration='2s' data-wow-delay="0.4s">
					<div className="col-lg-12">
						<ExhibitionSlider1 />
					</div>
				</div>
			</div>
		</>
	)
}
export { ExhibitionSection3 };

export default CompanyExhibition;
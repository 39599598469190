import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Index1 from './Pages/Homepage/Index1';
import Index2 from './Pages/Homepage/Index2';
import Index3 from './Pages/Homepage/Index3';
import Aboutus from './Pages/Aboutus/Aboutus';
import Aboutus2 from './Pages/Aboutus/Aboutus2';
import CompanyExhibition from './Pages/CompanyExhibition';
import PriceTable from './Pages/PriceTable';
import CompanyHistory from './Pages/CompanyHistory';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import ErrorPage from './Pages/ErrorPage';
import ComingSoon from './Pages/ComingSoon';

import SubscriptionBox1 from './Pages/SubscriptionBox/SubscriptionBox1';
import SubscriptionBox2 from './Pages/SubscriptionBox/SubscriptionBox2';
import Portfolio1 from './Pages/Portfolio/Portfolio1';
import Portfolio2 from './Pages/Portfolio/Portfolio2';
import ProjectDetail1 from './Pages/ProjectDetail/ProjectDetail1';
import ProjectDetail2 from './Pages/ProjectDetail/ProjectDetail2';
import ProjectDetail3 from './Pages/ProjectDetail/ProjectDetail3';
import ProjectDetail4 from './Pages/ProjectDetail/ProjectDetail4';
import BlogGrid from './Pages/Blog/BlogGrid';
import BlogList from './Pages/Blog/BlogList';
import BlogMasonry from './Pages/Blog/BlogMasonry';
import BlogDetails from './Pages/Blog/BlogDetails';
import ContactUs1 from './Pages/Contact/ContactUs1';
import ContactUs2 from './Pages/Contact/ContactUs2';

//ScrollToTop
import ScrollToTop from './Element/ScrollToTop';
import Portfolio3 from './Pages/Portfolio/Portfolio3';
import Gallery from './Pages/Portfolio/Gallery';
import Article from './Pages/Portfolio/Article';
import ArticleDetails from './Pages/Portfolio/ArticleDetails';
import FindHome from './Pages/FindHome/FindHome';
import QuickMoves from './Pages/QuickMoves/QuickMoves';
import BookNow from './Pages/BookNow/BookNow';
import Searching from './Pages/Searching/Searching';
import BookingDetails from './Pages/BookingDetails/BookingDetails';
import OurInvestor from './Pages/OurInvestor/OurInvestor';
import NoMatch from './Pages/NoMatch/NoMatch';
import MoveInHomesDetails from './Pages/MoveInHomes/MoveInHomesDetails';
import ReactModal from './Modal/ReactModal';

class Markup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showButton: false,
    }
  }
  componentDidMount() {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        this.setState({
          showButton: true,
        })
      } else {
        this.setState({
          showButton: false,
        })
      }
    });
  }
  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };
  render() {
    // For title
    // let title = window.location.href.split('/').slice(3, 5).join('/');
    // if (title == "") {
    //   document.title = "Aspen Square Homes";
    // } else {
    //   document.title = title;
    // }

    return (
      <BrowserRouter basename="/">
        <div className="page-wraper">
          <Switch>
            <Route path='/' exact component={Index1} />
            {/* <Route path='/index-2' exact component={Index2} />
                        <Route path='/index-3' exact component={Index3} /> */}
            <Route path='/about-us' exact component={Aboutus} />
            {/* <Route path='/about-us-2' exact component={Aboutus2} /> */}
            {/* <Route path='/company-exhibition' exact component={CompanyExhibition} /> */}
            {/* <Route path='/price-table' exact component={PriceTable} /> */}
            {/* <Route path='/company-history' exact component={CompanyHistory} /> */}
            <Route path='/privacy-policy' exact component={PrivacyPolicy} />
            <Route path='/404-page' exact component={ErrorPage} />
            {/* <Route path='/coming-soon' exact component={ComingSoon} /> */}
            {/* <Route path='/subscription-box-1' exact component={SubscriptionBox1} /> */}
            {/* <Route path='/subscription-box-2' exact component={SubscriptionBox2} /> */}
            <Route path='/communities/:slug' exact component={Portfolio1} />
            <Route path='/community/:slug' exact component={Portfolio2} />
            {/* <Route path='/mckinney' exact component={Portfolio2} /> */}
            {/* <Route path='/merritt-villas-townhomes' exact component={Portfolio3} /> */}
            {/* <Route path='/project-detail-1' exact component={ProjectDetail1} />
						<Route path='/project-detail-2' exact component={ProjectDetail2} />
						<Route path='/project-detail-3' exact component={ProjectDetail3} />
						<Route path='/project-detail-4' exact component={ProjectDetail4} /> */}
            {/* <Route path='/blog-grid' exact component={BlogGrid} /> */}
            {/* <Route path='/blog-list' exact component={BlogList} /> */}
            {/* <Route path='/blog-masonry' exact component={BlogMasonry} /> */}
            <Route path='/searching/:title' exact component={Searching} />
            <Route path='/blog-details' exact component={BlogDetails} />
            <Route path='/article-details/:id' exact component={ArticleDetails} />
            {/* <Route path='/contact-us-2' exact component={ContactUs1} /> */}
            <Route path='/contact-us' exact component={ContactUs2} />
            <Route path='/gallery' exact component={Gallery} />
            <Route path='/our-investors' exact component={OurInvestor} />
            <Route path='/articles' exact component={Article} />
            <Route path='/find-home' exact component={FindHome} />
            <Route path='/quick-move' exact component={QuickMoves} />
            <Route path='/floor-plans/:id' exact component={BookNow} />
            <Route path='/move-in-homes/:id' exact component={MoveInHomesDetails} />
            <Route path='/booking/:id' exact component={BookingDetails} />
            <Route path="*" exact component={NoMatch}>
              {/* <NoMatch /> */}
            </Route>
          </Switch>
        </div>
        <ScrollToTop />
        {this.state.showButton && (
          <button onClick={() => this.scrollToTop()} className="back-to-top">
            <i className="fa fa-arrow-up text-primary"></i>
          </button>
        )}
      </BrowserRouter>
    )
  }

}

export default Markup;

// Header Style Change
export const headerColor = (bgColor) =>{
    return{
        backgroundColor:`${bgColor}`,
    }
}

export const iconColor = (iconColor) =>{
    return{
        color:`${iconColor}`,
    }
}

export const iconWithInfo = (titleColor) =>{
    return{
        color:`${titleColor}`,
    }
}


// NavbarStyle Change
export const navBarBg = (bgColor) =>{
    return{
        backgroundColor:`${bgColor}`,
    }
}



// Btn Style Change

export const btnApplyStyle = (bgColor,color,border) =>{
    return{
        backgroundColor:`${bgColor}`,
        color:`${color}`,
        border:`1px solid ${border}`,
    }
}

export const btnHoverApplyStyle = (bgColor,color,border) =>{
    return{
        backgroundColor:`${bgColor}`,
        color:`${color}`,
        border:`1px solid ${border}`,
    }
}

// Input group button Apply
export const inputButtonPadding = () => {
	return {padding: "7px 30px"}
}
// Input group button
export const inputButton = (bgColor,color,border) =>{
    return{
        backgroundColor:`${bgColor}`,
        color:`${color}`,
        border:`1px solid ${border}`,
    }
}

export const inputButtonHover = (bgColor,color,border) =>{
    return{
        backgroundColor:`${bgColor}`,
        color:`${color}`,
        border:`1px solid ${border}`,
    }
}

// card button

export const cardButton = (bgColor,color,border) =>{
    return{
        backgroundColor:`${bgColor}`,
        color:`${color}`,
        border:`1px solid ${border}`,
    }
}

export const cardButtonHover = (bgColor,color,border) =>{
    return{
        backgroundColor:`${bgColor}`,
        color:`${color}`,
        border:`1px solid ${border}`,
    }
}

// Footer 


export const footerBg = (bgColor) =>{
    return{
        backgroundColor:`${bgColor}`,
    }
}

export const footerBottomBg = (bgColor) =>{
    return{
        backgroundColor:`${bgColor}`,
    }
}

export const homePageVision = (image) =>{
    return{
        backgroundImage:`url(${image})`,
    }
}


export const homePageVisionBg = (bgColor) =>{
    return {
        backgroundColor:`${bgColor}`,
    }
}


// breadcrumb style

export const breadcrumbStyle =(image)=>{
    return{
        backgroundImage:`url(${image})`,
    }
}

// export 
export function htmlToText(ourVisionDetails) {
    const el = document.createElement('div');
    el.innerHTML = ourVisionDetails == undefined ?'':ourVisionDetails;
    return el.textContent;
}






import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import SimpleReactLightbox from 'simple-react-lightbox';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import Header from './../../Layout/Header';
import Footer2 from './../../Layout/Footer2';
import PageTitle from './../../Layout/PageTitle';
//images
import './portfolio.css';
import { Modal } from 'react-bootstrap';
import breadcrumbImage from '../../../images/banner.png';
import Fade from 'react-reveal/Fade';


import { gallery } from '../../../Api/Actions/Gallery';
import { Helmet } from 'react-helmet';

// Masonry section
const masonryOptions = {
  transitionDuration: 0
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }
// Masonry section end





const TagLi = ({ name, handlesettag, tagActive }) => {
  return (

    <li className={` tag ${tagActive ? 'btn active' : 'btn'}`} onClick={() => handlesettag(name)} >
      <input type="radio" />
      <Link to={"#"} className="site-button-secondry button-skew" >	<span>{name} {''}</span> </Link>
    </li>
  );
};

const GalleryBox = ({ imageBlog }) => {
  const imageLink = process.env.REACT_APP_IMAGE_URL;
  const { openLightbox } = useLightbox()
  return (

    <div className="dlab-media dlab-img-overlay1 overlay-primary">
      <img src={imageLink + 'Subproject/Gallery/large/' + imageBlog.large_image} className="w-100 img-fluid" alt="" />
      <div className="overlay-bx">
        <div className="overlay-icon">
          {/* <a href="https://www.youtube.com/watch?v=Dj6CKxQue7U" className="popup-youtube">
                        <i className="fa fa-play icon-bx-xs"></i>
                    </a> */}
          <a onClick={() => openLightbox(imageBlog.id)} className=" " >
            <i className="fa fa-search icon-bx-xs"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export { GalleryBox };

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleSwitch: false,
      isOpen1: false,
      isOpen2: false,
      isOpen3: false,
      isOpen4: false,
      loading: true,
    };
    // this.tog_scroll = this.tog_scroll.bind(this);
  }
  componentDidMount() {
    new WOW.WOW().init();
    this.fetchGalleryImage();
  }


  fetchGalleryImage = () => {
    gallery().then((response) => {
      this.setState({
        gallery: response.data
      })
    })
  }


  // removeBodyCss() {
  // 	document.body.classList.add("no_padding");
  // }

  render() {
    let galleryList = [];
    if (this.state.hasOwnProperty('gallery')) {
      galleryList = this.state.gallery;
    }
    return (
      <Fragment>
        <Helmet>
          <title>Gallery -Aspen Square Homes</title>
          <meta name="description" content="Our mission is to enrich our customers' lives with an exceptional customer experience by connecting our customers with skillful and professional team members, provide innovative craftsmanship, designs, and products, and offer utmost personal care — one customer, one home, and one community at a time." />
          <meta property="og:title" content="Gallery -Aspen Square Homes" />
          <meta property="og:type" content="aspensquarehomes" />
          <meta property="og:description" content="Our mission is to enrich our customers' lives with an exceptional customer experience by connecting our customers with skillful and professional team members, provide innovative craftsmanship, designs, and products, and offer utmost personal care — one customer, one home, and one community at a time." />
          <meta property="og:url" content="https://aspensquarehomes.com/gallery" />
          <meta property="og:image" content={breadcrumbImage} />
        </Helmet>
        <Header />
        <div className="page-content bg-white">
          {/*  banner  */}
          <PageTitle motherMenu="Gallery" activeMenu="Gallery" breadcrumbImage={breadcrumbImage} />
          {/*  Section-1 Start  */}
          <section className="content-inner" data-content="GALLERY">
            <div className="container">
              <div className="section-head text-center">
                <div className="d-flex flex-column justify-content-center">
                  <h2 className="double-rules wow fadeInDown" data-wow-duration="2s" data-wow-delay="0.4s">PHOTO GALLERY</h2>
                </div>
              </div>
              <SimpleReactLightbox>
                <SRLWrapper >
                  <div className="row justify-content-center" id="lightgallery">
                    {
                      galleryList && galleryList.map((data, index) =>
                        <Fade bottom>
                          <div className="col-md-6 col-sm-12 col-lg-4">
                            <div className="dlab-box gallery-box-1 m-b30" key={index}>
                              <GalleryBox imageBlog={data} />
                            </div>
                          </div>
                        </Fade>
                      )
                    }
                  </div>
                </SRLWrapper>
              </SimpleReactLightbox>
            </div>
          </section>

        </div>
        <Footer2 />
      </Fragment>
    )
  }
}
export default Gallery;
import React,{Component} from 'react';
import { Link } from "react-scroll";

class SideNav extends Component{
	render(){
		return(
			<>
				<ul className="navbar">
					<li>
						<Link
							activeClass="active"  to="sidenav_home" smooth={true} offset={-70} duration={500} className="scroll nav-link " >
							<i className="las la-home text-white"></i> <span className="text-white">HOME</span>
						</Link>
					</li>

					<li>
						<Link 
							activeClass="active" to="sidenav_aboutUs" className="scroll nav-link "  smooth={true} offset={-70} duration={500} >
							<i className="las la-user text-white"></i> <span className="text-white">VISION</span>
						</Link>
					</li>
					<li>
						<Link 
							activeClass="active" to="sidenav_masterPlan" className="scroll nav-link"  smooth={true} offset={-70} duration={500}  >
							<i className="las la-chart-bar text-white"></i> <span className="text-white">NEW & NOW</span>
						</Link>
					</li>
					<li>
						<Link 
							activeClass="active" to="sidenav_connect" className="scroll nav-link"  smooth={true} offset={-70} duration={500} >
							<i className="las la-map text-white"></i> <span className="text-white">OUR FOOTPRINT</span>
						</Link>
					</li>
				
					
				</ul>
			</>
		)
	}
}

export default SideNav;
import React, { Fragment, Component } from 'react';
import Footer2 from '../../Layout/Footer2';
import Header from '../../Layout/Header';
import PageTitle from '../../Layout/PageTitle';
import bgimg from './../../../images/bg-view.png';
import BookingInfo from './FakeBookData';
import data from '../../../staticData/staticData.js';
import WOW from 'wowjs';
import { Button, Dropdown, Modal, Table } from 'react-bootstrap';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import aspenMap from '../../../assets/BookingInfo/Map_Aspen.jpg';
import aspenMap1 from '../../../assets/BookingInfo/Map_Aspen_Phase_I.jpg';
import { Link } from 'react-router-dom';
import { cardButton, cardButtonHover } from '../../CommonStyle/CommonStyle';
import breadcrumbImage from '../../../images/banner.png';
import { availableBookingInfo, bookCreate, bookingDetails, similarBookingInfo } from '../../../Api/Actions/bookNow';

class BookingDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            bookInfo: BookingInfo,
            otherInfo: [],
            book: [],
            isOpen: false,
            isOpen2: false,
            isOpen3: false,
            isHover: true,
            sorting: 0,
            otherSorting: 0,

        }
        this.wrapper = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.otherHandleChange = this.otherHandleChange.bind(this);
    }
    componentDidMount() {
        new WOW.WOW().init();
        this.dataFetchBooking();
        let id = window.location.pathname.split('/').pop();
        this.fetchSimilarLot(id);
        this.fetchFamiliarLot(id);
        this.fetchBookingInfo(id);
    }

    // api call

    fetchBookingInfo = (id) => {
        bookingDetails(id).then((response) => {
            this.setState({
                bookingInfo: response.data[0],
                titleInfo: response.data.floore_plans_names
            })
        })
    }

    fetchSimilarLot = (id) => {
        availableBookingInfo(id).then((response) => {
            this.setState({
                availableLot: response.data
            })
        })
    }

    fetchFamiliarLot = (id) => {
        similarBookingInfo(id).then((response) => {
            this.setState({
                similarLot: response.data
            })
        })
    }

    openModal = (bookInfo) => {
        this.setState({ isOpen: true, bookIn: bookInfo })
        // console.log('bookInfo', bookInfo)
    };
    closeModal = () => { this.setState({ isOpen: false, bookIn: '' }) };

    openModal2 = () => {
        this.setState({ isOpen2: true })
    };
    closeModal2 = () => {

        this.setState({ isOpen2: false });
        this.props.history.push('/');

    };

    openModal3 = () => {
        this.setState({ isOpen3: true })
    };
    closeModal3 = () => {
        this.setState({ isOpen3: false });
    };

    dataFetchBooking = () => {
        let id = window.location.pathname.split('/').pop();
        this.setState({
            BookingInfo: BookingInfo,
        })
        let bookInfo = BookingInfo && BookingInfo.filter(dataInfo => dataInfo.f_id == id).map(d => d);
        let otherInfo = BookingInfo && BookingInfo.filter(dataInfo => dataInfo.f_id != id).map(d => d);
        this.setState({
            bookInfo: bookInfo,
            otherInfo: otherInfo,
        })

        this.state.bookInfo = bookInfo;
        this.state.otherInfo = otherInfo;

    }

    autoSearchInputChange = (event) => {
        event.preventDefault();
        const { target: { name, value } } = event;
        this.updateBookObject(name, value);
    }

    updateBookObject = (name, value) => {
        let bookCopy = Object.assign({}, this.state.book);
        bookCopy[name] = value;
        this.setState({
            book: bookCopy
        });
    }
    onHandleSubmit = (e) => {
        e.preventDefault();
        const { Buyer_First_Name, Buyer_Phone, Buyer_Email, Buyer_Zip, Buyer_State, Buyer_City, Buyer_Address_1, Buyer_Last_Name, Buyer_Middle_Name } = this.state.book;
        const data = {
            f_available_Lots_Aspen_id: this.state.bookIn.id,
            Model: this.state.bookIn && this.state.bookIn.Model,
            Lot: this.state.bookIn && this.state.bookIn.Lot,
            Block: this.state.bookIn && this.state.bookIn.Block,
            Buyer_First_Name: Buyer_First_Name,
            Buyer_Middle_Name: Buyer_Middle_Name == undefined ? '' : Buyer_Middle_Name,
            Buyer_Last_Name: Buyer_Last_Name == undefined ? '' : Buyer_Last_Name,
            Buyer_Address_1: Buyer_Address_1,
            Buyer_City: Buyer_City,
            Buyer_State: Buyer_State,
            Buyer_Zip: Buyer_Zip,
            Buyer_Email: Buyer_Email,
            Buyer_Phone: Buyer_Phone,
        }

        bookCreate(data)
            .then((response) => {
                if (response.data.status == true) {
                    this.closeModal();
                    this.openModal2();
                }
                if (response.data.status == false) {
                    this.closeModal();
                    this.openModal3();
                }
            }
            ).catch((err) => {
                console.log(err)
            })
    }




    handleChange = (e) => {
        this.setState({ sorting: e.target.value });
        this.state.sorting = e.target.value;
        if (e.target.value == 0) {
            let id = window.location.pathname.split('/').pop();
            let unsorted = this.state.availableLot && this.state.availableLot.filter(dataInfo => dataInfo.f_id == id).map(d => d);
            this.setState({ availableLot: unsorted });
        }
        else if (e.target.value == 1) {
            let sqft = this.state.availableLot && this.state.availableLot.sort((a, b) => b.SQFT - a.SQFT).map(d => d);
            this.setState({ availableLot: sqft })
        }
        else if (e.target.value == 2) {
            let block = this.state.availableLot && this.state.availableLot.sort((a, b) => a.Block.localeCompare(b.Block)).map(d => d);
            this.setState({ availableLot: block })
        }
    }

    otherHandleChange = (e) => {
        this.setState({ sorting: e.target.value });
        this.state.otherSorting = e.target.value;
        if (e.target.value == 0) {
            let id = window.location.pathname.split('/').pop();
            let unsortedOther = this.state.similarLot && this.state.similarLot.filter(dataInfo => dataInfo.f_id != id).map(d => d);
            this.setState({ similarLot: unsortedOther });
        }
        else if (e.target.value == 1) {
            let sqftOther = this.state.similarLot && this.state.similarLot.sort((a, b) => b.SQFT - a.SQFT).map(d => d);
            this.setState({ similarLot: sqftOther })
        }
        else if (e.target.value == 2) {
            let blockOther = this.state.similarLot && this.state.similarLot.sort((a, b) => a.Block.localeCompare(b.Block)).map(d => d);
            this.setState({ similarLot: blockOther })
        }
    }

    handleMouseEnter = () => {
        this.setState({
            isHover: true,
        })
    };
    handleMouseLeave = () => {
        this.setState({
            isHover: false,
        })
    };

    render() {
        // let id = window.location.pathname.split('/').pop();
        const { isHover } = this.state;

        const imageLink = process.env.REACT_APP_IMAGE_URL;
        let availableLot = [];
        let similarLot = [];
        let bookingInfo = [];
        if (this.state.hasOwnProperty('availableLot')) {
            availableLot = this.state.availableLot;
        }
        if (this.state.hasOwnProperty('similarLot')) {
            similarLot = this.state.similarLot;
        }

        if (this.state.hasOwnProperty('bookingInfo')) {
            bookingInfo = this.state.bookingInfo;
        }
        let titleInfo = [];
        if (this.state.hasOwnProperty('titleInfo')) {
            titleInfo = this.state.titleInfo;
        }
        // console.log({bookingInfo});



        return (
            <Fragment>
                <Header />
                <div className="page-content bg-white">
                    {/*  banner  */}
                    <PageTitle motherMenu="Booking" activeMenu="Booking" breadcrumbImage={imageLink + 'company_settings/aboutus/sub/project_cover_image_1/' + bookingInfo.project_cover_image_1} />
                    {/*  Section-1 Start  */}
                    <section className="content-inner" data-content="BOOKING">
                        <div className='container'>
                            <h2 className="double-rules wow fadeInDown" data-wow-duration='2s' data-wow-delay="0.4s">{titleInfo} Booking</h2>
                        </div>
                    </section>
                    <section>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6 ">
                                    <div className="wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">
                                        <h3 className="wow fadeInDown text-center text-uppercase" data-wow-duration='2s' data-wow-delay="0.4s">Selected Available Lot of {titleInfo} Model </h3>
                                        {/* <Dropdown className='mb-3 '>
                                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                                Filter
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={this.sortingBySelectedSQFT}>Available Lots By SQFT</Dropdown.Item>
                                                <Dropdown.Item onClick={this.sortingBySelectedBlock}>Available Lots By Block</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown> */}
                                        <select className="custom-select my-2 w-25" id="inputGroupSelect04" name="sorting" aria-label="select-button" style={{ fontSize: '14px' }} value={this.state.sorting} onChange={this.handleChange}>
                                            <option value={0}>UNSORTED</option>
                                            <option value={1}>Available Lots By SQFT</option>
                                            <option value={2}>Available Lots By Block</option>
                                        </select>
                                        <Table bordered striped responsive >
                                            <thead className='thead-dark'>
                                                <tr className='py-0'>
                                                    <th scope="col">S/N</th>
                                                    <th scope="col">Building</th>
                                                    <th scope="col">Block</th>
                                                    <th scope="col">Lot</th>
                                                    <th scope="col">Model</th>
                                                    <th scope="col">SQFT</th>
                                                    <th scope="col">Phase</th>
                                                    <th scope="col">Choose</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {availableLot.length == 0 ?
                                                    <tr>
                                                        <td className="text-center" colSpan="8"><h3 className="text-danger text-uppercase">NO AVAILABLE LOT OF {titleInfo} MODEL</h3></td>
                                                    </tr> :
                                                    availableLot && availableLot.map((data, index) =>
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{data.Building_No}</td>
                                                            <td>{data.Block}</td>
                                                            <td>{data.Lot}</td>
                                                            <td>{data.Model}</td>
                                                            <td>{data.SQFT}</td>
                                                            <td>{data.Phase}</td>
                                                            <td>
                                                                <button className='btn btn-sm btn-primary' onClick={() => this.openModal(data)} style={cardButton('#000', '#fff', '#569539')}>Book</button>
                                                            </td>
                                                        </tr>

                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div className="wow fadeInUp mt-5" data-wow-duration='2s' data-wow-delay="0.4s">
                                        <h3 className="wow fadeInDown text-center text-uppercase" data-wow-duration='2s' data-wow-delay="0.4s">Available Lot of Related Other Model </h3>
                                        <select className="custom-select my-2 w-25" id="inputGroupSelect04" name="sorting" aria-label="select-button" style={{ fontSize: '14px' }} value={this.state.otherSorting} onChange={this.otherHandleChange}>
                                            <option value={0}>UNSORTED</option>
                                            <option value={1}>Available Lots By SQFT</option>
                                            <option value={2}>Available Lots By Block</option>
                                        </select>
                                        <Table bordered striped responsive>
                                            <thead className='thead-dark'>
                                                <tr className='py-0'>
                                                    <th scope="col">S/N</th>
                                                    <th scope="col">Building</th>
                                                    <th scope="col">Block</th>
                                                    <th scope="col">Lot</th>
                                                    <th scope="col">Model</th>
                                                    <th scope="col">SQFT</th>
                                                    <th scope="col">Phase</th>
                                                    <th scope="col">Choose</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {similarLot.length == 0 ?
                                                    <tr>
                                                        <td className="text-center" colSpan="8"><h3 className="text-danger text-uppercase">NO AVAILABLE LOT OF Related Other MODEL</h3></td>
                                                    </tr> :
                                                    similarLot && similarLot.map((data, index) =>
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{data.Building_No}</td>
                                                            <td>{data.Block}</td>
                                                            <td>{data.Lot}</td>
                                                            <td>{data.Model}</td>
                                                            <td>{data.SQFT}</td>
                                                            <td>{data.Phase}</td>
                                                            <td>
                                                                <button className='btn btn-sm btn-primary' onClick={() => this.openModal(data)} style={cardButton('#000', '#fff', '#569539')}>Book</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className="col-md-6 p-0">
                                    {
                                        bookingInfo.booking_sitemap_image == null || bookingInfo.booking_sitemap_image == "" || bookingInfo.booking_sitemap_image == undefined ? '' :
                                            <div className="m-2" style={{ border: '10px solid #569539' }} >
                                                <h3 className="text-center text-uppercase">{titleInfo} Available Lots</h3>
                                                <TransformWrapper
                                                    initialScale={1}
                                                >
                                                    {({ zoomIn, zoomOut, resetTransform, positionX, positionY, ...rest }) => (
                                                        <>
                                                            <div className="tools d-flex justify-content-center my-2">
                                                                <button className="btn btn-sm btn-outline-primary mx-2" onClick={() => zoomIn()}><i className="fa fa-search-plus fa-1x "></i></button>
                                                                <button className="btn btn-sm btn-outline-danger mx-2" onClick={() => zoomOut()}><i className="fa fa-search-minus fa-1x "></i></button>
                                                                <button className="btn btn-sm btn-outline-secondary mx-2" onClick={() => resetTransform()}><i className="fa fa-refresh fa-1x "></i></button>
                                                            </div>
                                                            <div className="d-flex justify-content-center p-0">
                                                                <TransformComponent>
                                                                    <img src={imageLink + 'company_settings/aboutus/sub/booking_sitemap_image/' + bookingInfo.booking_sitemap_image} style={{ height: '500px' }} className="img-fluid mx-auto d-block" alt="map image" />
                                                                </TransformComponent>
                                                            </div>
                                                        </>
                                                    )}

                                                </TransformWrapper>
                                            </div>
                                    }

                                    {/* <div className="wow fadeInUp m-2" data-wow-duration='2s' data-wow-delay="0.4s" style={{ border: '10px solid #569539' }} >
                                        <h3 className="wow fadeInDown text-center text-uppercase" data-wow-duration='2s' data-wow-delay="0.4s">{bookingInfo.project_title} Phase I Map</h3>
                                        <TransformWrapper
                                            initialScale={1}
                                        >
                                            {({ zoomIn, zoomOut, resetTransform, positionX, positionY, ...rest }) => (
                                                <>
                                                    <div className="tools d-flex justify-content-center my-2">
                                                        <button className="btn btn-sm btn-outline-primary mx-2" onClick={() => zoomIn()}><i className="fa fa-plus fa-1x "></i></button>
                                                        <button className="btn btn-sm btn-outline-danger mx-2" onClick={() => zoomOut()}><i className="fa fa-minus fa-1x "></i></button>
                                                        <button className="btn btn-sm btn-outline-secondary mx-2" onClick={() => resetTransform()}><i className="fa fa-times fa-1x "></i></button>
                                                    </div>
                                                    <TransformComponent>
                                                        <img src={aspenMap1} className="img-fluid mx-auto d-block" alt="phase image" />
                                                    </TransformComponent>
                                                </>
                                            )}

                                        </TransformWrapper>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <Modal show={this.state.isOpen} onHide={this.closeModal} size="xl" animation={true} scrollable>
                            <Modal.Header closeButton>
                                <Modal.Title>{this.state.bookIn && this.state.bookIn.Model} Booking Form</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <form className="contact-box dzForm p-a30 border-1" onSubmit={this.onHandleSubmit}>
                                            <h3 className="title-box">Please provide us with your information if you want to purchase a property</h3>
                                            <div className="dzFormMsg m-b20"></div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input name="id" type="hidden" className="form-control" placeholder="id" value={this.state.bookIn && this.state.bookIn.id} onChange={(e) => this.autoSearchInputChange(e)} />
                                                        <div className="input-group">
                                                            <label>Buyer First Name<sup className='text-danger'>*</sup></label>
                                                            <small>If an LLC is buying it, put your LLC name here and your full name in the Middle Name and leave the Last Name field blank</small>
                                                            <input name="Buyer_First_Name" type="text" required className="form-control" placeholder="First Name*" onChange={(e) => this.autoSearchInputChange(e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <label>Buyer Middle Name</label>
                                                        <div className="input-group">
                                                            <input name="Buyer_Middle_Name" type="text" className="form-control" placeholder="Middle Name" onChange={(e) => this.autoSearchInputChange(e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <label>Buyer Last Name</label>
                                                            <small>If multiple buyers, please provide the last person's name in this field with an and before the name, and the rest in the First name like: First_Name: John Adams, Willium Bill Last_Name: and Andrew Simon</small>
                                                            <input name="Buyer_Last_Name" type="text" className="form-control" placeholder="Last Name" onChange={(e) => this.autoSearchInputChange(e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="form-group">
                                                        <label>Buyer Address<sup className='text-danger'>*</sup></label>
                                                        <div className="input-group">
                                                            <input name="Buyer_Address_1" type="text" required className="form-control" placeholder=" Address*" onChange={(e) => this.autoSearchInputChange(e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="form-group">
                                                        <label>Buyer City <sup className='text-danger'>*</sup></label>
                                                        <div className="input-group">
                                                            <input name="Buyer_City" type="text" className="form-control" required placeholder="City*" onChange={(e) => this.autoSearchInputChange(e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="form-group">
                                                        <label>Buyer State<sup className='text-danger'>*</sup></label>
                                                        <div className="input-group">
                                                            <input name="Buyer_State" type="text" className="form-control" required placeholder="State*" onChange={(e) => this.autoSearchInputChange(e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="form-group">
                                                        <label>Buyer Zip<sup className='text-danger'>*</sup></label>
                                                        <div className="input-group">
                                                            <input name="Buyer_Zip" type="text" className="form-control" required placeholder="Zip*" onChange={(e) => this.autoSearchInputChange(e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="form-group">
                                                        <label>Buyer Email<sup className='text-danger'>*</sup></label>
                                                        <div className="input-group">
                                                            <input name="Buyer_Email" type="email" className="form-control" required placeholder="Email*" onChange={(e) => this.autoSearchInputChange(e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="form-group">
                                                        <label>Buyer Phone<sup className='text-danger'>*</sup></label>
                                                        <div className="input-group">
                                                            <input name="Buyer_Phone" type="text" className="form-control" required placeholder="Phone*" onChange={(e) => this.autoSearchInputChange(e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <label>Block and Lot<sup className='text-danger'>*</sup></label>
                                                        <div className="input-group">
                                                            <input name="Block_and_Lot" className="form-control" required placeholder="Block and Lot*"></input>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="form-group">
                                                        <label>Block<sup className='text-danger'>*</sup></label>
                                                        {/* <br />
                                                        <small>If your Block is A and Lot number is 1, the input will be:  A-1</small> */}
                                                        <div className="input-group">
                                                            <input name="Block" className="form-control text-secondary" value={this.state.bookIn && this.state.bookIn.Block} readOnly required placeholder="Block*" onChange={(e) => this.autoSearchInputChange(e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="form-group">
                                                        <label>Lot<sup className='text-danger'>*</sup></label>
                                                        {/* <br />
                                                        <small>If your Block is A and Lot number is 1, the input will be:  A-1</small> */}
                                                        <div className="input-group">
                                                            <input name="Lot" className="form-control text-secondary" value={this.state.bookIn && this.state.bookIn.Lot} required placeholder="Lot*" readOnly onChange={(e) => this.autoSearchInputChange(e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <label>Model</label>
                                                        <div className="input-group">
                                                            <input name="Model" className="form-control text-secondary" value={this.state.bookIn && this.state.bookIn.Model} required placeholder="Model" readOnly onChange={(e) => this.autoSearchInputChange(e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-12">
                                                    <div className="form-group form-recaptcha">
                                                        <div className="input-group">
                                                            <div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
                                                            <input className="form-control d-none" style={{ display: "block" }} data-recaptcha="true" required data-error="Please complete the Captcha" />
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="col-lg-12 col-md-12">
                                                    <button type="submit" className="btn btn-primary btn-lg" style={isHover == true ? cardButtonHover('#569539', '#fff', '#fff') : cardButton('#000', '#fff', '#569539')} onMouseOver={() => this.handleMouseEnter()} onMouseLeave={() => this.handleMouseLeave()}  > Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className='col-md-6'>
                                        {
                                            this.state.bookIn && this.state.bookIn.Lots_image_1 == null ? '' :
                                                <div>
                                                    <h3 className='m-2 text-center'>Selected Lot</h3>
                                                    {this.state.bookIn && <img src={imageLink + 'Lot/Image/Lots_image_1/' + this.state.bookIn.Lots_image_1} style={{ height: '20%' }} className="mx-auto d-block d-flex justify-content-center" />}
                                                </div>
                                        }

                                        {

                                            bookingInfo.booking_sitemap_image == null || bookingInfo.booking_sitemap_image == "" || bookingInfo.booking_sitemap_image == undefined ? '' :
                                                <div className=" m-2" style={{ border: '10px solid #569539' }} >
                                                    <h3 className="text-center text-uppercase">{titleInfo} Available Lots</h3>

                                                    <TransformWrapper
                                                        initialScale={1}
                                                    >
                                                        {({ zoomIn, zoomOut, resetTransform, positionX, positionY, ...rest }) => (
                                                            <>
                                                                <div className="tools d-flex justify-content-center my-2">
                                                                    <button className="btn btn-sm btn-outline-primary mx-2" onClick={() => zoomIn()}><i className="fa fa-search-plus fa-1x "></i></button>
                                                                    <button className="btn btn-sm btn-outline-danger mx-2" onClick={() => zoomOut()}><i className="fa fa-search-minus fa-1x "></i></button>
                                                                    <button className="btn btn-sm btn-outline-secondary mx-2" onClick={() => resetTransform()}><i className="fa fa-refresh fa-1x "></i></button>
                                                                </div>
                                                                <div className="d-flex justify-content-center p-0">
                                                                    <TransformComponent>
                                                                        <img src={imageLink + 'company_settings/aboutus/sub/booking_sitemap_image/' + bookingInfo.booking_sitemap_image} style={{ height: '500px' }} className="img-fluid mx-auto d-block " alt="map image" />
                                                                    </TransformComponent>
                                                                </div>
                                                            </>
                                                        )}
                                                    </TransformWrapper>
                                                </div>
                                        }

                                        {/* <div className="m-2" d style={{ border: '10px solid #569539' }} >
                                            <h3 className="text-center text-uppercase">{bookingInfo.project_title} Phase I Map</h3>
                                            <TransformWrapper
                                                initialScale={1}
                                            >
                                                {({ zoomIn, zoomOut, resetTransform, positionX, positionY, ...rest }) => (
                                                    <>
                                                        <div className="tools d-flex justify-content-center my-2">
                                                            <button className="btn btn-sm btn-outline-primary mx-2" onClick={() => zoomIn()}><i className="fa fa-plus fa-1x "></i></button>
                                                            <button className="btn btn-sm btn-outline-danger mx-2" onClick={() => zoomOut()}><i className="fa fa-minus fa-1x "></i></button>
                                                            <button className="btn btn-sm btn-outline-secondary mx-2" onClick={() => resetTransform()}><i className="fa fa-times fa-1x "></i></button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src={aspenMap1} className="img-fluid mx-auto d-block" />
                                                        </TransformComponent>
                                                    </>
                                                )}
                                            </TransformWrapper>
                                        </div> */}
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={this.closeModal}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={this.state.isOpen2} size="md" animation={true} centered>
                            <Modal.Body>
                                <div className="row justify-content-center">
                                    <div className="col-md-12">
                                        <div className="d-flex justify-content-center">
                                            <i className="fa fa-check text-light fa-2x icon-style"></i>
                                        </div>
                                        <div className="mt-3">
                                            <h4 className="text-center text-uppercase text-uppercase">Successfully Submitted</h4>
                                            <p className="text-center" style={{ fontSize: '10px' }}>Thank You ❤️. One of our responsible person contact with you asap!</p>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <button className="btn btn-primary" style={isHover == true ? cardButtonHover('#569539', '#fff', '#fff') : cardButton('#000', '#fff', '#569539')} onMouseOver={() => this.handleMouseEnter()} onMouseLeave={() => this.handleMouseLeave()} onClick={this.closeModal2}>Ok</button>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>

                        </Modal>


                        <Modal show={this.state.isOpen3} size="md" animation={true} centered>
                            <Modal.Body>
                                <div className="row justify-content-center">
                                    <div className="col-md-12">
                                        <div className="d-flex justify-content-center">
                                            <i className="fa fa-times text-light fa-2x icon-style2"></i>
                                        </div>
                                        <div className="mt-3">
                                            <h4 className="text-center text-uppercase">Sorry 😔</h4>
                                            <p className="text-center" style={{ fontSize: '10px' }}>This lot is already booked!</p>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <button className="btn btn-primary" style={isHover == true ? cardButtonHover('#569539', '#fff', '#fff') : cardButton('#000', '#fff', '#569539')} onMouseOver={() => this.handleMouseEnter()} onMouseLeave={() => this.handleMouseLeave()} onClick={this.closeModal3}>Ok</button>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>

                        </Modal>
                    </section>

                    {/* <section className="content-inner" style={{ backgroundColor: 'rgba(0, 0, 0, 0.20)' }}>

                    </section> */}

                    <div className="mt-2">
                        <img src={imageLink + 'company_settings/aboutus/sub/project_cover_image_2/' + bookingInfo.project_cover_image_2} className="bg-view" alt="" />
                    </div>
                </div >
                <Footer2 />
            </Fragment >
        )
    }
}

export default BookingDetails
import { getApi } from "../../services/httpService";


export function communitiesInfo() {
  return getApi().get('home/comunites/list');
}

export function communitiesDetails(slug) {
  return getApi().get('home/comunites/list/' + slug)
}

export function communitiesFloorPlan(slug){
    return getApi().get('home/floorPlan/comunites/list/'+slug)
}

export function communitiesFloorPlanUpComingMoveIn(slug){
    return getApi().get('home/floorPlan/upComingMoveIn/list/'+slug)
}

export function communitiesFloorPlanUpComingHome(slug){
    return getApi().get('home/floorPlan/upComingHome/list/'+slug)
}
export function moveInFloorPlan(slug) {
  return getApi().get('home/floorPlan/upComingMoveIn/list/' + slug)
}
export function upComingFloorPlan(slug) {
  return getApi().get('home/floorPlan/upComingHome/list/' + slug)
}
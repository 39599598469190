import React, { Fragment, Component } from 'react';
import map from './../../images/map.jpg';
import sitemap from './../../images/sitemap.jpg';


class Mapview extends Component {
	render() {
		return (
			<Fragment>
				<div className="map-view">
					<div className="row spno">
						<div className="col-md-6">
							{/* <img src={map} alt="" /> */}
							<img src="https://aspensquarehomes.com/wp-content/uploads/2022/06/Merritt_Villas_Site_Plan.jpg" alt="" />
							{/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d227748.3825624477!2d75.65046970649679!3d26.88544791796718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c4adf4c57e281%3A0xce1c63a0cf22e09!2sJaipur%2C+Rajasthan!5e0!3m2!1sen!2sin!4v1500819483219"
								className="border-0 align-self-stretch rounded-sm" height="100%"
								width="100%" allowfullscreen
							/> */}
						</div>
						<div className="col-md-6">
							<iframe
								src="https://aspensquarehomes.com/wp-content/uploads/2021/08/Site_Plan.pdf"
								frameBorder="0"
								scrolling="none"
								height="100%"
								width="100%">
							</iframe>
							{/* <img src="https://aspensquarehomes.com/wp-content/uploads/2022/06/Merritt_Villas_Site_Plan.jpg" className="w-75 img-fluid img-responsive" alt="" /> */}
							{/* <img src={sitemap} alt="" /> */}

						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}
export default Mapview;
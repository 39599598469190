import React, { Component } from 'react';
import Slider from "react-slick";


// import galery1 from './../../images/gallery/gallery-6/pic1.jpg';
// import galery2 from './../../images/gallery/gallery-6/pic2.jpg';
// import galery3 from './../../images/gallery/gallery-6/pic3.jpg';
// import galery4 from './../../images/gallery/gallery-6/pic4.jpg';

import dying from './../../assets/Projects/dying.jpg';
import entertainment from './../../assets/Projects/entertainment.jpg';
import workstation from './../../assets/Projects/workstation.jpg';
import guest2 from './../../assets/Projects/guest2.jpg';
import { htmlToText } from '../CommonStyle/CommonStyle';


const sliderFakeData = [
	{
		image: entertainment,
	},
	{
		image: dying,
	},
	{
		image: workstation,
	},
	{
		image: guest2,
	},
]


function SampleNextArrow(props) {
	const { onClick } = props;
	return (
		<div className="owl-nav">
			<div className="owl-next la la-angle-right" onClick={onClick} />
		</div>
	);
}

function SamplePrevArrow(props) {
	const { onClick } = props;
	return (
		<div className="owl-nav">
			<div className=" owl-prev la la-angle-left" onClick={onClick} style={{ zIndex: 1 }} />
		</div>
	);
}

class ExhibitionSlider1 extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		// let location = window.location.href.split('/').pop();
		const imageLink = process.env.REACT_APP_IMAGE_URL;
		var settings = {
			arrows: true,
			dots: true,
			slidesToShow: 1,
			infinite: true,
			autoplay: true,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 1,

					}
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 1,

					}
				},
				{
					breakpoint: 576,
					settings: {
						slidesToShow: 1,

					}
				}
			]
		};
		const { communitiesDetails } = this.props;
		let feature_image = [];
		feature_image =  communitiesDetails.feature_image  && communitiesDetails.feature_image.filter(imgInfo => imgInfo.is_brochure != 1 && imgInfo.is_active == 1).map((imgInfo, index) => imgInfo);

		let communitiesShortPrint = <div dangerouslySetInnerHTML={{ __html: htmlToText(communitiesDetails.project_short_description) }} />
		let communitiesDetailsPrint = <div dangerouslySetInnerHTML={{ __html: communitiesDetails.project_details_description }} />

		return (
			<>
				{ feature_image && feature_image.length == 0? "" :
					<Slider className="exhibition-carousel owl-carousel owl-none m-b30 " {...settings}>
						{feature_image && feature_image.map((imgInfo, index) =>
							<div className="item">
								<img className="w-100" src={imageLink + 'Subproject/' + imgInfo.f_project_names_id + '/Slider/large/' + imgInfo.slider_large_image} alt="" />
							</div>
						)}
					</Slider>
				}
				<div className="company-info">
					<h3 className="text-uppercase wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.2s">{communitiesShortPrint}</h3>
					<p className="wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s"> {communitiesDetailsPrint}</p>
				</div>
			</>


		)

	}

}

export default ExhibitionSlider1;